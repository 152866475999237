import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomIcon, IconButton, Tooltip, useAddToast, useModal } from "@toolkit/ui";
import { useChatFlowDocumentDeleteMutation } from "../gql";
export const DeleteDocsButtons = ({ docId, fileName }) => {
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const { open, handleToggle } = useModal();
    const [deleteDoc, { loading }] = useChatFlowDocumentDeleteMutation({
        onCompleted: () => {
            succeeded(t("Document deleted successfully"));
        },
        onError: ({ graphQLErrors }) => {
            failed(t(formatGraphQLError(graphQLErrors)));
        },
        update: cache => {
            const normalizedId = cache.identify({ id: docId, __typename: "ChatFlowDocument" });
            cache.evict({ id: normalizedId });
            cache.gc();
        },
    });
    const handleDeleteDocsClick = () => {
        deleteDoc({
            variables: {
                chatFlowDocumentDeleteId: docId,
            },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: t("DeleteDocs Agent") }, { children: _jsx(IconButton, Object.assign({ onClick: handleToggle, disabled: loading }, { children: _jsx(CustomIcon, { icon: 'delete' }) })) })), open && (_jsx(CustomDialog, { type: 'delete', text: {
                    body: t("Are you sure you want to delete this agent?"),
                    title: t(`Delete {{fileName}}`, { fileName: fileName || "this" }),
                }, isOpen: open, onClose: handleToggle, onDelete: handleDeleteDocsClick }))] }));
};
