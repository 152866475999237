import { useCustomFormContext } from "@toolkit/core";
import { get } from "lodash";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { Box, FormHelperText } from "../../../base/mui";
import { MDTextEditor } from "../../MDTextEditor";

type FormMDTextEditorProps = {
  name: string;
  label?: string;
  placeholder?: string;
  token?: string;
  direction?: "ltr" | "rtl";
};

export const FormMDTextEditor: FC<FormMDTextEditorProps> = props => {
  const { name, label, placeholder, direction } = props;

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MDTextEditor {...field} label={label} placeholder={placeholder} readonly={isFormDisabled} direction={direction} />
        )}
      />

      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
