import React, { FC } from "react";
import { Box, Card, CardContent, CustomIcon, IconButton, MuiRestoreIcon, paths, Typography } from "@toolkit/ui";
import { interventionActivityTypeOptionsMap } from "../../../enum-options/InterventionActivityType";
import { getInterventionActivityIcon } from "../../../others";
import { useGuidedCareJourneyInterventionActivitiesItemsStyle } from "./useGuidedCareJourneyInterventionActivitiesItemsStyle";
import { useTranslation } from "@toolkit/i18n";
import { Moment } from "moment/moment";
import { ITemplateInterventionActivityItem } from "../../../types/types";
import classNames from "classnames";

export type IGuidedCareJourneyInterventionActivitiesItemsEvent = {
  type: "DELETE";
  payload: {
    activityItemId: string;
  };
};

type GuidedCareJourneyInterventionActivitiesItemsProps = {
  interventionActivitiesItems: ITemplateInterventionActivityItem[];
  selectedDate: Moment | null;
  onChange: (event: IGuidedCareJourneyInterventionActivitiesItemsEvent) => void;
};

export const GuidedCareJourneyInterventionActivitiesItems: FC<GuidedCareJourneyInterventionActivitiesItemsProps> = props => {
  const { interventionActivitiesItems, selectedDate, onChange } = props;

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareJourneyInterventionActivitiesItemsStyle();

  const onDeleteActivityItem = (activityItemId: string) => {
    onChange({
      type: "DELETE",
      payload: { activityItemId },
    });
  };

  return (
    <Box>
      {interventionActivitiesItems?.length ? (
        interventionActivitiesItems?.map(item => {
          const { icon, viewBox } = getInterventionActivityIcon(item?.templateActivityType!);

          return (
            <Card key={item?.id} className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Box className={classes.wrapper}>
                  <Box className={classes.infoWrapper}>
                    <Box className={classNames(classes.iconWrapper, { [classes.deletedIcon]: item?.isDeleted })}>
                      <CustomIcon icon={icon as keyof typeof paths} width={25} height={25} viewBox={viewBox} />
                    </Box>

                    <Box>
                      <Typography className={classNames(classes.templateActivityType, { [classes.deletedText]: item?.isDeleted })}>
                        {interventionActivityTypeOptionsMap[item?.templateActivityType!]?.label}
                      </Typography>

                      <Typography className={classNames(classes.date, { [classes.deletedText]: item?.isDeleted })}>
                        {selectedDate?.clone()?.add(item?.offsetInDays, "day").format("D MMMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>

                  <IconButton
                    color={"primary"}
                    className={classNames(classes.deleteRestoreIconWrapper, { [classes.deletedIcon]: item?.isDeleted })}
                    onClick={() => onDeleteActivityItem(item?.id!)}
                  >
                    {item?.isDeleted ? (
                      <MuiRestoreIcon className={classes.restoreIcon} />
                    ) : (
                      <CustomIcon icon={"deletionBucket"} viewBox={"-1 -2 27 27"} />
                    )}
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <Typography>{t("There are no activities for this intervention")}</Typography>
      )}
    </Box>
  );
};
