import { GuidedCareActivityType } from "@health/queries/types";
import { IEnumToOptionsMap } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";

export const interventionActivityTypeOptionsMap: IEnumToOptionsMap<GuidedCareActivityType> = {
  [GuidedCareActivityType.FacilityVisit]: {
    key: GuidedCareActivityType.FacilityVisit,
    get label() {
      return i18n.t("In-Person Visit", { ns: "provider" });
    },
    value: GuidedCareActivityType.FacilityVisit,
  },
  [GuidedCareActivityType.HomeVisit]: {
    key: GuidedCareActivityType.HomeVisit,
    get label() {
      return i18n.t("Home Visit", { ns: "provider" });
    },
    value: GuidedCareActivityType.HomeVisit,
  },
  [GuidedCareActivityType.OnlineVisit]: {
    key: GuidedCareActivityType.OnlineVisit,
    get label() {
      return i18n.t("Virtual Visit", { ns: "provider" });
    },
    value: GuidedCareActivityType.OnlineVisit,
  },
  [GuidedCareActivityType.MedicalForm]: {
    key: GuidedCareActivityType.MedicalForm,
    get label() {
      return i18n.t("Medical Form", { ns: "provider" });
    },
    value: GuidedCareActivityType.MedicalForm,
  },
  [GuidedCareActivityType.MedicalMessageGeneral]: {
    key: GuidedCareActivityType.MedicalMessageGeneral,
    get label() {
      return i18n.t("Messages - General", { ns: "provider" });
    },
    value: GuidedCareActivityType.MedicalMessageActivityInstruction,
  },
  [GuidedCareActivityType.MedicalMessageActivityInstruction]: {
    key: GuidedCareActivityType.MedicalMessageActivityInstruction,
    get label() {
      return i18n.t("Messages - Instruction", { ns: "provider" });
    },
    value: GuidedCareActivityType.MedicalMessageActivityInstruction,
  },
  [GuidedCareActivityType.MedicalMessageMedical]: {
    key: GuidedCareActivityType.MedicalMessageMedical,
    get label() {
      return i18n.t("Messages - Medical", { ns: "provider" });
    },
    value: GuidedCareActivityType.MedicalMessageMedical,
  },
  [GuidedCareActivityType.MedicalMessageLifestyleReminder]: {
    key: GuidedCareActivityType.MedicalMessageLifestyleReminder,
    get label() {
      return i18n.t("Messages - Reminder", { ns: "provider" });
    },
    value: GuidedCareActivityType.MedicalMessageLifestyleReminder,
  },
  [GuidedCareActivityType.MedicalMessageMonitoring]: {
    key: GuidedCareActivityType.MedicalMessageMonitoring,
    get label() {
      return i18n.t("Messages - Monitoring", { ns: "provider" });
    },
    value: GuidedCareActivityType.MedicalMessageMonitoring,
  },
  [GuidedCareActivityType.Lab]: {
    key: GuidedCareActivityType.Lab,
    get label() {
      return i18n.t("Lab Checkup", { ns: "provider" });
    },
    value: GuidedCareActivityType.Lab,
  },
  [GuidedCareActivityType.Rad]: {
    key: GuidedCareActivityType.Rad,
    get label() {
      return i18n.t("Rad Checkup", { ns: "provider" });
    },
    value: GuidedCareActivityType.Rad,
  },
};

export const interventionActivityTypeOptions = Object.values(interventionActivityTypeOptionsMap);
