import { HealthParameter, VisitStatus, VisitType, VisitVitalSigns, VisitVitalSignsInput } from "@health/queries/types";
import { capitalize } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { IVitalSignsUpdateFormValues } from "pages/e-visit/VitalSigns/forms/VitalSignUpdate/VitalSignUpdateFormSchema";
import { vitalSignsCode } from "shared/modules/patient/components/VitalSigns/utils";

export const isVisitDetailsEnabled = (status: VisitStatus) => {
  return (
    status === VisitStatus.Completed ||
    status === VisitStatus.CallInProgress ||
    status === VisitStatus.Incompleted ||
    status === VisitStatus.CallRejoinable
  );
};

export const getVisitStatus = (status?: VisitStatus | null) => {
  if (!status) return;

  return capitalize(status.toLowerCase().split("_"));
};

export const getCareType = (type?: VisitType | null) => {
  if (!type) return;

  return capitalize(type.toLowerCase().split("_"));
};

export const convertVisitVitalSignToFormValues = (
  healthParameterVitalSigns: Array<HealthParameter | null | undefined>,
  visitVitalSigns: VisitVitalSigns[]
): IVitalSignsUpdateFormValues["items"] => {
  const vitalSignsFields = healthParameterVitalSigns
    ?.filter(item => Object.values(vitalSignsCode).includes(item?.code!))
    ?.filter(item => item?.code !== vitalSignsCode?.bloodPressureLow && item?.code !== vitalSignsCode?.bloodPressureHigh)
    ?.map(item => {
      const value = visitVitalSigns?.find(visitVitalSign => visitVitalSign?.hpCode === item?.code)?.valueNumber;

      return {
        value: parseFloat(value) || undefined,
        hpCode: item?.code!,
        display: item?.display!,
        arabicDisplay: item?.arabicDisplay!,
      };
    });

  const hasBloodPressureLow = healthParameterVitalSigns?.find(item => item?.code === vitalSignsCode.bloodPressureLow);
  const hasBloodPressureHigh = healthParameterVitalSigns?.find(item => item?.code === vitalSignsCode.bloodPressureHigh);
  const hasBloodPressure = hasBloodPressureLow && hasBloodPressureHigh;

  const bloodPressureLowValue = visitVitalSigns?.find(
    visitVitalSign => visitVitalSign?.hpCode === vitalSignsCode.bloodPressureLow
  )?.valueNumber;
  const bloodPressureHighValue = visitVitalSigns?.find(
    visitVitalSign => visitVitalSign?.hpCode === vitalSignsCode.bloodPressureHigh
  )?.valueNumber;

  const bloodPressureItem = {
    value:
      bloodPressureLowValue && bloodPressureHighValue
        ? `${parseFloat(bloodPressureHighValue)}/${parseFloat(bloodPressureLowValue)}`
        : undefined,
    hpCode: `${hasBloodPressureHigh?.code}/${hasBloodPressureLow?.code}`,
    display: i18n.t("Blood Pressure", { ns: "provider" }),
    arabicDisplay: i18n.t("Blood Pressure", { ns: "provider" }),
  };

  return hasBloodPressure ? [bloodPressureItem, ...vitalSignsFields!] : vitalSignsFields;
};

export const convertVisitVitalSignFormValuesToBackEndValues = (values: IVitalSignsUpdateFormValues): VisitVitalSignsInput[] => {
  const items =
    values?.items
      ?.filter(item => item?.value && item?.hpCode !== `${vitalSignsCode?.bloodPressureHigh}/${vitalSignsCode?.bloodPressureLow}`)
      ?.map(item => ({
        hpCode: item?.hpCode!,
        valueNumber: item?.value || null,
      })) || [];

  const bloodPressure = values?.items?.find(
    item => item?.hpCode === `${vitalSignsCode?.bloodPressureHigh}/${vitalSignsCode?.bloodPressureLow}`
  );

  // eslint-disable-next-line no-unsafe-optional-chaining
  const [bloodPressureHigh, bloodPressureLow] = bloodPressure?.value ? (bloodPressure?.value as string)?.split("/") : [];

  const bloodPressureValues =
    bloodPressureHigh && bloodPressureLow
      ? [
          {
            hpCode: vitalSignsCode?.bloodPressureHigh,
            valueNumber: bloodPressureHigh ? Number(bloodPressureHigh) : null,
          },
          {
            hpCode: vitalSignsCode?.bloodPressureLow,
            valueNumber: bloodPressureLow ? Number(bloodPressureLow) : null,
          },
        ]
      : [];

  return [...items, ...bloodPressureValues];
};
