import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { Maybe } from "@health/queries/types";
import moment from "moment-timezone";
import { useState } from "react";
import { AppointmentsDocument, TentativeAppointmentConfirmMutation, useTentativeAppointmentConfirmMutation } from "pages/appointments/gql";
import useToggle from "shared/hooks/useToggle";

type TentativeAppointmentConfirmType = {
  id: string;
  doctorId: string;
  requestedTimeSlots?: Maybe<Maybe<string>[]>;
};

const format = "YYYY-MM-DD";

export const useTentativeAppointmentConfirm = ({ id, doctorId, requestedTimeSlots }: TentativeAppointmentConfirmType) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const { open, handleToggle } = useToggle();

  const requestedStartTime = requestedTimeSlots?.[0] && JSON.parse(requestedTimeSlots?.[0])?.startTime;

  const [errors, setErrors] = useState(false);
  const [dateTimeRange, setDateTimeRange] = useState({
    date: moment(requestedStartTime).format(format),
    startTime: null,
    endTime: null,
  });

  const isExpiredDate = moment(requestedStartTime, format).isBefore(moment().format(format), "day");
  const isValidFields = dateTimeRange?.date && dateTimeRange?.startTime && dateTimeRange?.endTime;

  const [tentativeAppointmentConfirm] = useTentativeAppointmentConfirmMutation({
    onCompleted: (mutationData: TentativeAppointmentConfirmMutation) => {
      const appointmentErrors = mutationData?.tentativeAppointmentConfirm?.appointmentErrors;
      if (appointmentErrors?.length) {
        failed(formatMessageErrors(appointmentErrors));
      } else {
        succeeded(t("Appointment Confirmed Successfully"));
        handleToggle();
        setErrors(false);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors) || t("Appointment Confirmed failed"));
    },
    refetchQueries: [
      {
        query: AppointmentsDocument,
        variables: {
          first: 10,
          includeCancellationReason: false,
          filter: {
            status: "TENTATIVE",
          },
        },
      },
    ],
  });

  const handleDateTimeChange = (field: string, value) => {
    setDateTimeRange(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleAcceptNurseForAppointment = () => {
    if (isValidFields && doctorId) {
      setErrors(false);

      const confirmedStartTime = moment(dateTimeRange?.date)
        .set({
          hour: moment(dateTimeRange?.startTime).get("hour"),
          minute: moment(dateTimeRange?.startTime).get("minute"),
        })
        .format("YYYY-MM-DDTHH:mm:ssZ");

      const confirmedEndTime = moment(dateTimeRange?.date)
        .set({
          hour: moment(dateTimeRange?.endTime).get("hour"),
          minute: moment(dateTimeRange?.endTime).get("minute"),
        })
        .format("YYYY-MM-DDTHH:mm:ssZ");

      tentativeAppointmentConfirm({
        variables: {
          id,
          input: {
            doctor: doctorId,
            confirmedStartTime,
            confirmedEndTime,
          },
        },
      });
    } else {
      setErrors(true);
    }
  };

  return {
    t,
    dateTimeRange,
    open,
    handleToggle,
    errors,
    isExpiredDate,
    isValidFields,
    handleDateTimeChange,
    handleAcceptNurseForAppointment,
  };
};
