export enum JourneyDurationPeriod {
  Annual = "Annual",
  Quarterly = "Quarterly",
}

export enum JourneyActivityScope {
  AllActivities = "AllActivities",
  MyActivities = "MyActivities",
}

export enum JourneyActivityFilter {
  ShowAll = "ShowAll",
  HideMedicalMessages = "HideMedicalMessages",
}

export enum HealthParameterStethoscopeAndOtoscopeCodes {
  StethoscopeHeartSound = "IoT002",
  StethoscopeLungSound = "IoT003",
  OtoscopeVideoRecord = "IoT004",
  OtoscopePicture = "IoT005",
}

export type IPatientProfileTeamMember = {
  key: string;
  label: string;
  value: string;
};
