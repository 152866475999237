import { useHealthParametersChanged } from "@health/common";
import { sourceOptionsMap } from "@health/enum-options";
import { H_OrderDirection, PatientHealthParameterSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, ExpandIcon, Grid, IconButton, Typography, useModal } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { useGetHealthParametersQuery, usePatientHealthParametersQuery } from "shared/modules/patient/gql";
import { HealthParameterStethoscopeAndOtoscopeCodes } from "shared/modules/patient/types";
import { getApolloContextFormToken } from "shared/utils";
import { PatientHealthParameterStethoscopeAndOtoscopeListModal } from "../../modals/PatientHealthParameterList/PatientHealthParameterStethoscopeAndOtoscopeListModal";
import { HealthParameterStethoscopeAndOtoscopeType } from "../HealthParameterStethoscopeAndOtoscopeType/HealthParameterStethoscopeAndOtoscopeType";
import { useHealthParameterStethoscopeAndOtoscopeStyle } from "./useHealthParameterStethoscopeAndOtoscopeStyle";

type HealthParameterStethoscopeAndOtoscopeProps = {
  token: string;
  code: HealthParameterStethoscopeAndOtoscopeCodes;
  visitId?: string;
  patientId: string;
  height?: number;
};

export const HealthParameterStethoscopeAndOtoscope: FC<HealthParameterStethoscopeAndOtoscopeProps> = props => {
  const { token, code, visitId, patientId, height = 200 } = props;

  const { t } = useTranslation("provider");
  const { open, handleOpen, handleClose } = useModal();

  const { classes } = useHealthParameterStethoscopeAndOtoscopeStyle({ height });

  const { data, loading } = useGetHealthParametersQuery({
    context: getApolloContextFormToken(token!),
    variables: {
      first: 1,
      filter: {
        code: [code],
      },
    },
  });

  const healthParameter = data?.getHealthParameters?.edges?.[0]?.node;

  const {
    data: patientHealthParametersData,
    loading: isPatientHealthParametersLoading,
    refetch,
  } = usePatientHealthParametersQuery({
    variables: {
      first: 1,
      filter: {
        visitId,
        patientId: [patientId!],
        codes: [healthParameter?.code!],
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    },
    skip: !patientId || !healthParameter?.code,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    context: getApolloContextFormToken(token!),
  });

  const patientHealthParameter = patientHealthParametersData?.patientHealthParameters?.edges?.[0]?.node;

  const isLoading = loading || isPatientHealthParametersLoading;

  useHealthParametersChanged(({ data: healthParametersData }) => {
    if (!healthParametersData?.healthParameters?.length) return;

    const healthParameterItem = healthParametersData.healthParameters.find(item => item.code === code);

    if (!healthParameterItem) return;

    refetch({
      first: 10,
      filter: {
        visitId,
        patientId: [patientId!],
        codes: [healthParameterItem?.code!],
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    });
  });

  const url = patientHealthParameter?.valueAudioFile! || patientHealthParameter?.valueVideoFile!;
  return (
    <Box className={classes.root}>
      {isLoading ? (
        <CircularProgress size={50} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box className={classes.infoWrapper}>
                <Box>
                  <Typography className={classes.title}>{healthParameter?.display}</Typography>

                  <Typography className={classes.date}>
                    {moment(patientHealthParameter?.createdDate).format("DD MMM YYYY hh:mm")}
                  </Typography>

                  {patientHealthParameter?.source && (
                    <Typography className={classes.source}>
                      {t("By")} {`${sourceOptionsMap[patientHealthParameter?.source]?.label} ${patientHealthParameter?.deviceName}`}
                    </Typography>
                  )}
                </Box>

                <IconButton onClick={handleOpen}>
                  <ExpandIcon />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xs={url ? 4 : 12}>
              <HealthParameterStethoscopeAndOtoscopeType url={url} />
            </Grid>

            <PatientHealthParameterStethoscopeAndOtoscopeListModal
              isOpen={open}
              title={healthParameter?.display!}
              code={code}
              token={token}
              patientId={patientId}
              visitId={visitId}
              url={url}
              onClose={handleClose}
            />
          </Grid>
        </>
      )}
    </Box>
  );
};
