import { GuidedCareTaskUpdateModal } from "../../modals/GuidedCareTaskUpdate/GuidedCareTaskUpdateModal";
import { GuidedCareTask } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { EditIcon, IconButton, useAddToast, useModal } from "@toolkit/ui";
import { useGuidedCareTaskUpdateMutation } from "pages/GuidedCareTasks/gql";
import { convertGuidedCareTaskFormValuesToBackEndValues } from "pages/GuidedCareTasks/others";
import { IGuidedCareTaskUpdateFormEvent } from "pages/GuidedCareTasks/types";
import { FC } from "react";

type GuidedCareTaskUpdateProps = {
  guidedCareTask: GuidedCareTask;
};

export const GuidedCareTaskUpdate: FC<GuidedCareTaskUpdateProps> = props => {
  const { guidedCareTask } = props;

  const { t } = useTranslation("provider");
  const { open, handleOpen, handleClose } = useModal();
  const { succeeded, failed } = useAddToast();

  const [updateGuidedCareTask, { loading }] = useGuidedCareTaskUpdateMutation({
    onCompleted: data => {
      const errors = data?.guidedCareTaskUpdate?.entityErrors;

      if (!errors?.length) {
        succeeded(t("Task updated successfully"));
      } else {
        failed(t("Task update failed"));
      }

      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      handleClose();
    },
  });

  const onGuidedCareTaskUpdateModalChange = (event: IGuidedCareTaskUpdateFormEvent) => {
    if (event.type === "UPDATE") {
      const values = convertGuidedCareTaskFormValuesToBackEndValues(event.payload.values, guidedCareTask);

      updateGuidedCareTask({
        variables: {
          id: guidedCareTask?.id!,
          input: values,
        },
      });
    }
  };

  return (
    <>
      <IconButton size={"small"} color={"primary"} disabled={loading} onClick={handleOpen}>
        <EditIcon fontSize={"small"} />
      </IconButton>

      <GuidedCareTaskUpdateModal
        isOpen={open}
        isLoading={loading}
        guidedCareTask={guidedCareTask}
        onClose={handleClose}
        onChange={onGuidedCareTaskUpdateModalChange}
      />
    </>
  );
};
