import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, ErrorIcon, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useModal } from "shared/hooks/useModel";

export const DeclineModel: FC<{ onDecline: () => void }> = ({ onDecline }) => {
  const { t } = useTranslation("provider");
  const { open, handleToggle } = useModal();
  const theme = useTheme();
  const handleDecline = () => {
    handleToggle();
    onDecline();
  };

  return (
    <>
      <CustomDialog
        type='base'
        maxWidth='sm'
        open={open}
        DialogTitleProps={{
          onClose: handleToggle,
        }}
        DialogActionsProps={{
          children: (
            <Button variant='outlined' color='error' onClick={handleDecline}>
              {t("Cancel")}
            </Button>
          ),
        }}
        button={
          <Button variant='outlined' onClick={handleToggle}>
            {t("Cancel")}
          </Button>
        }
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 120 }}>
          <Box
            sx={{
              "& svg": {
                width: 50,
                height: 50,
              },
            }}
          >
            <ErrorIcon />
          </Box>
          <Typography
            color={theme.palette.primary.main}
            mt={1}
            fontSize={theme.mixins.fonts.fontSize.lg}
            fontWeight='bold'
            width={342}
            align='center'
          >
            {t("Are you sure you need to Decline Call?")}
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
};
