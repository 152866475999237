export type LabCode = "a1c" | "acr" | "abi" | "pbl" | "pbh" | "bmd" | "bmi" | "retinopathy" | "egfr" | "ldl" | "cvd";

export const labs: { display: string; code: LabCode; options?: string[]; type: "string" | "number" | "index" }[] = [
  {
    display: "HbA1C",
    code: "a1c",
    type: "number",
  },
  {
    display: "Albumin-to-Creatinine Ration (ACR)",
    code: "acr",
    type: "number",
  },
  {
    display: "Ankle-Brachial Index",
    code: "abi",
    type: "number",
  },
  {
    display: "Bone Minerial Density",
    code: "bmd",
    type: "number",
  },
  {
    display: "Blood Pressure (systolic)",
    code: "pbh",
    type: "number",
  },
  {
    display: "Blood Pressure (diastolic)",
    code: "pbl",
    type: "number",
  },
  {
    display: "Body Mass Index (BMI)",
    code: "bmi",
    type: "number",
  },
  {
    display: "Retinopathy",
    code: "retinopathy",
    type: "string",
    options: ["no or simplex retinopathy", "progression of retinopathy", "macula edema or prolitferative retinopathy"],
  },
  {
    display: "Estimated Glomerular Filtration Rate ",
    code: "egfr",
    type: "number",
  },
  {
    display: "LDL",
    code: "ldl",
    type: "number",
  },
  {
    code: "cvd",
    display: "Cardiovascular Disease",
    options: ["no", "1 major cardiovascular event", ">1 major cardiovascular event"],
    type: "index",
  },
];
