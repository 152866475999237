import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useVendorAcceptHealthPackageOrderMutation } from "../gql";

export const AcceptHealthPackageOrderButton: FC<{ orderId: string }> = ({ orderId }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [fetchVendorAcceptHealthPackageOrder, { loading: isSubmittingReject }] = useVendorAcceptHealthPackageOrderMutation({
    onCompleted: () => {
      succeeded(t("Order accepted successfully"));
    },
    onError: error => {
      failed(t(formatGraphQLError(error.graphQLErrors)));
    },
    update: cache => {
      const normalizedId = cache.identify({ id: orderId, __typename: "MarketplaceOrder" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleAcceptClick = () => {
    fetchVendorAcceptHealthPackageOrder({
      variables: {
        id: orderId!,
      },
    });
  };
  return (
    <Button sx={{ margin: "5px" }} color={"success"} disabled={isSubmittingReject} onClick={handleAcceptClick}>
      {t("Accept")}
    </Button>
  );
};
