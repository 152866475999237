import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useSetOrderToOutForDeliveryMutation } from "../gql";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";

export const DispenseButton: FC<{ id: string; submitLabel: string; onDone?: () => void }> = ({ id, submitLabel, onDone }) => {
  const { t } = useTranslation("provider");
  const { classes } = useOrderActionStyles();
  const { failed, succeeded } = useAddToast();

  const [setOrderToOutForDeliveryMutation, { loading }] = useSetOrderToOutForDeliveryMutation({
    onCompleted: data => {
      if (data?.setOrderToOutForDelivery?.orderErrors?.length === 0) {
        succeeded(t("Order is now out for delivery"));
        onDone?.();
      } else {
        const msg = formatMessageErrors(data?.setOrderToOutForDelivery?.orderErrors);
        failed(t(msg));
      }
    },
  });

  const handleOrderVendorFinishProcessing = (): void => {
    setOrderToOutForDeliveryMutation({
      variables: { setOrderToOutForDeliveryId: id },
    });
  };

  const className = submitLabel === t("Dispense eRx") ? `${classes.accept} ${classes.dispense} ` : classes.accept;

  return (
    <CircularIntegration loading={loading}>
      <Button disabled={loading} className={className} onClick={handleOrderVendorFinishProcessing}>
        {submitLabel}
      </Button>
    </CircularIntegration>
  );
};
