import { useOpenStateController } from "@health/common";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  Button,
  Checkbox,
  CustomDialog,
  CustomIcon,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
  useAddToast,
} from "@toolkit/ui";
import React, { FC, useState } from "react";
import { useVendorOrderCancelMutation } from "../gql";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderCancelModelStyles } from "./OrderCancelModel.styles";

export const OrderCancelButton: FC<{ id: string; patientName: string | null; patientMobile: string }> = props => {
  const { t } = useTranslation("provider");
  const { id, patientName, patientMobile } = props;
  const { succeeded, failed } = useAddToast();
  const { classes } = useOrderCancelModelStyles();
  const cancelText = t("Cancel Order");
  const { open, handleOpen, handleClose } = useOpenStateController();
  const [cancellationReason, setCancellationReason] = useState("");
  const [checked, setChecked] = useState(false);

  const [vendorOrderCancel, { loading }] = useVendorOrderCancelMutation({
    onCompleted: data => {
      if (data?.vendorOrderCancel?.orderErrors?.length === 0) {
        succeeded(t("Order Canceled Successfully"));
        handleClose();
        setChecked(false);
        setCancellationReason("");
      } else {
        const msg = formatMessageErrors(data?.vendorOrderCancel?.orderErrors);
        failed(t(msg));
      }
    },
  });
  const handleCancellationReasonChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setCancellationReason(e.target.value);
  };

  const handleChangeChecked = e => {
    setChecked(e.target.checked);
  };

  const handleVendorOrderCancel = () => {
    vendorOrderCancel({
      variables: {
        id,
        cancellationReason,
      },
    });
  };

  return (
    <CustomDialog
      type='base'
      open={open}
      classes={{
        paper: classes.paper,
      }}
      DialogTitleProps={{
        onClose: handleClose,
        title: t("eRx Cancellation"),
      }}
      button={
        <Tooltip title={cancelText}>
          <Button color='error' variant='outlined' className={classes.cancel} onClick={handleOpen}>
            {cancelText}
          </Button>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <>
            <CircularIntegration loading={loading}>
              <Button className={classes.reject} disabled={!checked || !cancellationReason} onClick={handleVendorOrderCancel}>
                {cancelText}
              </Button>
            </CircularIntegration>
            <Button onClick={handleClose} variant='outlined'>
              {t("Close")}
            </Button>
          </>
        ),
      }}
    >
      <Typography className={`${classes.title} ${classes.mobile}`} gutterBottom>
        {patientName}
      </Typography>

      <Box className={classes.box}>
        <a href={`tel:${patientMobile}`} className={classes.call}>
          <CustomIcon icon='call' transform='rotate(90deg)' viewBox='-2 -2 27 27' width={40} height={40} />
        </a>
        <Typography className={`${classes.title}`} gutterBottom>
          {patientMobile}
        </Typography>
      </Box>

      <TextField
        fullWidth
        size='small'
        value={cancellationReason}
        label={t("Cancellation Reason")}
        placeholder={t("Cancellation Reason")}
        onChange={handleCancellationReasonChange}
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={<Checkbox onChange={handleChangeChecked} checked={checked} />}
        label={t("I contacted the patient to confirm the cancellation")}
      />
    </CustomDialog>
  );
};
