/* eslint-disable max-lines */
import { useCustomForm } from "@toolkit/core";
import {
  IGuidedCareProgramMemberAddFormValues,
  guidedCareProgramMemberAddFormDefaultValues,
  guidedCareProgramMemberAddFormSchema,
} from "./GuidedCareProgramMemberAddFormSchema";
import { FormProvider } from "react-hook-form";
import { Box, Button, CircularProgress, Grid, useAddToast } from "@toolkit/ui";
import { GuidedCareProgramsAutocomplete } from "@health/autocompletes";
import React, { FC, useEffect } from "react";
import { GuidedCareProgramMemberRiskFactorParametersForm } from "../GuidedCareProgramMemberRiskFactorParameters/GuidedCareProgramMemberRiskFactorParametersForm";
import {
  useGuidedCareProgramMemberAddMutation,
  useGuidedCareProgramGetQuery,
  useGuidedCareProgramMemberRiskFactorParametersEvaluateMutation,
} from "../../gql";
import { useGuidedCareProgramMemberAddFormStyle } from "./useGuidedCareProgramMemberAddFormStyle";
import {
  convertGuidedCareProgramRiskFactorTemplateFieldsValuesToBackEndValues,
  convertRiskFactorTemplateEvaluateToFormValues,
  convertRiskFactorTemplateFieldsToFormValues,
} from "../../others";
import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { GuidedCareProgramMemberRiskFactorParametersEvaluationForm } from "../GuidedCareProgramMemberRiskFactorParametersEvaluation/GuidedCareProgramMemberRiskFactorParametersEvaluationForm";
import { GuidedCareProgramMemberAddModalApi } from "../../modals/GuidedCareProgramMemberAdd/GuidedCareProgramMemberAddModal";
import { useProfileContext } from "../../context/ProfileContext";

type GuidedCareProgramMemberAddFormProps = {
  payerId?: string;
  visitId?: string;
};

export const GuidedCareProgramMemberAddForm: FC<GuidedCareProgramMemberAddFormProps> = props => {
  const { payerId, visitId } = props;

  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const { patient } = useProfileContext();

  const defaultMember = patient?.activeHealthProgramMembers?.find(item => item?.isDefault);

  const { classes } = useGuidedCareProgramMemberAddFormStyle();

  const form = useCustomForm<IGuidedCareProgramMemberAddFormValues>({
    defaultValues: guidedCareProgramMemberAddFormDefaultValues,
    schema: guidedCareProgramMemberAddFormSchema,
  });

  const { watch, setValue, handleSubmit } = form;

  const program = watch("program");
  const suggestedProgram = watch("suggestedProgram");
  const riskFactorTemplateFields = watch("riskFactorTemplateFields");
  const evaluationResult = watch("evaluationResult");

  const payer = visitId ? payerId : defaultMember?.payer?.id;

  const isButtonEvaluate =
    riskFactorTemplateFields?.length && (!evaluationResult || (evaluationResult && !evaluationResult?.suggestedPrograms?.length));

  const { data: guidedCareProgramData, loading: isGuidedCareProgramLoading } = useGuidedCareProgramGetQuery({
    variables: {
      id: program?.value?.id,
    },
    skip: !program?.value?.id,
  });

  const guidedCareHealthProgramTemplateRiskFactorFields =
    guidedCareProgramData?.guidedCareHealthProgram?.guidedCareHealthProgramTemplate?.riskFactorTemplate?.fields;

  const [addGuidedCareProgramMember, { loading: isInvitingLoading }] = useGuidedCareProgramMemberAddMutation({
    onCompleted: mutationData => {
      if (mutationData?.addMemberToProgram?.id) {
        succeeded(t("Added to program successfully"));
      } else {
        failed(t("Failed to add to program"));
      }

      GuidedCareProgramMemberAddModalApi.close();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const [evaluateGuidedCareProgramMemberRiskFactorParameters, { loading: isEvaluationSubmitting }] =
    useGuidedCareProgramMemberRiskFactorParametersEvaluateMutation({
      onCompleted: mutationData => {
        const _evaluationResult = convertRiskFactorTemplateEvaluateToFormValues(mutationData?.evaluateGuidedCareProgramRiskFactorTemplate);

        setValue("evaluationResult", _evaluationResult);
      },
      onError: ({ graphQLErrors }) => {
        failed(formatGraphQLError(graphQLErrors));
      },
    });

  const onSubmit = (values: IGuidedCareProgramMemberAddFormValues) => {
    if (isButtonEvaluate) {
      evaluateGuidedCareProgramMemberRiskFactorParameters({
        variables: {
          input: {
            visitId,
            memberId: defaultMember?.id,
            programId: values?.program?.value?.id,
            result: convertGuidedCareProgramRiskFactorTemplateFieldsValuesToBackEndValues(values?.riskFactorTemplateFields),
          },
        },
      });
    } else {
      addGuidedCareProgramMember({
        variables: {
          input: {
            visitId,
            memberId: !visitId ? defaultMember?.id : undefined,
            guidedCareProgramId: suggestedProgram ? values?.suggestedProgram?.value?.id! : values?.program?.value?.id,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (guidedCareHealthProgramTemplateRiskFactorFields?.length) {
      const _riskFactorTemplateFields = convertRiskFactorTemplateFieldsToFormValues(guidedCareHealthProgramTemplateRiskFactorFields);
      setValue("riskFactorTemplateFields", _riskFactorTemplateFields);
    } else {
      setValue("riskFactorTemplateFields", []);
      setValue("evaluationResult", undefined);
    }
  }, [guidedCareHealthProgramTemplateRiskFactorFields, setValue]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GuidedCareProgramsAutocomplete name={"program"} skip={!payer} filter={{ payerId: payer, isActive: true }} />
          </Grid>

          {isGuidedCareProgramLoading || isInvitingLoading ? (
            <Box className={classes.loadingWrapper}>
              <CircularProgress color={"inherit"} size={30} />
            </Box>
          ) : (
            riskFactorTemplateFields?.length > 0 && (
              <Grid item xs={12}>
                <GuidedCareProgramMemberRiskFactorParametersForm />
              </Grid>
            )
          )}

          {isEvaluationSubmitting ? (
            <Box className={classes.loadingWrapper}>
              <CircularProgress color={"inherit"} size={30} />
            </Box>
          ) : (
            evaluationResult && (
              <Grid item xs={12}>
                <GuidedCareProgramMemberRiskFactorParametersEvaluationForm />
              </Grid>
            )
          )}
        </Grid>

        <Box className={classes.footer}>
          <Button variant={"contained"} type={"submit"} disabled={isInvitingLoading || isEvaluationSubmitting}>
            {isButtonEvaluate ? t("Evaluate") : t("Invite")}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
