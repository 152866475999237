import { sourceOptionsMap } from "@health/enum-options";
import { PatientHealthParameter } from "@health/queries/types";
import { detectFileType, FileType } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { CustomTableColumnProps, IconButton, MuiPlayCircleIcon, MuiVisibilityIcon } from "@toolkit/ui";
import moment from "moment";

type PatientHealthParameterStethoscopeAndOtoscopeListColumnsProps = {
  t: TFunction;
  selectedUrl: string;
  onChange: (valueAudioUrl: string) => void;
};

export const usePatientHealthParameterStethoscopeAndOtoscopeListColumns = (
  props: PatientHealthParameterStethoscopeAndOtoscopeListColumnsProps
): CustomTableColumnProps<PatientHealthParameter>[] => {
  const { t, selectedUrl, onChange } = props;

  const fileType = detectFileType(selectedUrl);
  const isFileTypeImage = fileType === FileType.IMAGE;

  const onPlayCircleIconClick = (fileUrl: string) => {
    onChange(fileUrl);
  };

  return [
    {
      key: "source",
      header: t("Source"),
      accessor: ({ source, deviceName }) => `${sourceOptionsMap[source!]?.label} ${deviceName}`,
    },
    {
      key: "date",
      header: t("Date"),
      accessor: ({ createdDate }) => moment(createdDate)?.format("DD MMM YYYY hh:mm"),
    },
    {
      key: "play",
      header: isFileTypeImage ? t("Preview") : t("Play"),
      accessor: ({ valueAudioFile, valueVideoFile }) => {
        const isPlaying = valueAudioFile ? selectedUrl === valueAudioFile : selectedUrl === valueVideoFile;

        return (
          <IconButton disabled={isPlaying} onClick={() => onPlayCircleIconClick(valueAudioFile! || valueVideoFile!)}>
            {isFileTypeImage ? <MuiVisibilityIcon color={"primary"} /> : <MuiPlayCircleIcon color={"primary"} />}
          </IconButton>
        );
      },
    },
  ];
};
