import {
  useGetNotificationCountAfterNewNotification,
  useGetNotificationCountAfterReadAllNotification,
  useGetNotificationCountAfterReadANotification,
} from "@health/common";
import { NotificationType } from "@health/queries/types";
import { Badge, CustomDropdown } from "@toolkit/ui";
import { useState } from "react";
import { UnReadMessagesCountQuery, useUnReadMessagesCountQuery } from "./gql";
import { NotificationsContainer } from "./NotificationsContainer";
import { useNotificationSound } from "./utils";
export const Notifications = () => {
  const [unReadNotificationsCount, setUnReadNotificationsCount] = useState(0);
  const [lastNotificationsCountChangeAction, setLastNotificationsCountChangeAction] = useState<NotificationType | undefined>();

  const { playNotificationSound } = useNotificationSound();

  useGetNotificationCountAfterReadANotification(({ data }) => {
    setLastNotificationsCountChangeAction(NotificationType.MarkAsRead);
    setUnReadNotificationsCount(data?.count);
  });
  useGetNotificationCountAfterReadAllNotification(({ data }) => {
    setLastNotificationsCountChangeAction(NotificationType.MarkAllAsRead);
    setUnReadNotificationsCount(data?.count);
  });
  useGetNotificationCountAfterNewNotification(({ data }) => {
    setLastNotificationsCountChangeAction(NotificationType.NewNotification);
    playNotificationSound();
    setUnReadNotificationsCount(data?.count);
  });
  useUnReadMessagesCountQuery({
    onCompleted: (data: UnReadMessagesCountQuery) => {
      if (data?.me?.unReadMessagesCount) {
        setUnReadNotificationsCount(data?.me?.unReadMessagesCount);
      }
    },
    fetchPolicy: "cache-first",
  });

  return (
    <Badge badgeContent={unReadNotificationsCount} color='error'>
      <CustomDropdown>
        <NotificationsContainer
          unReadMessagesCount={unReadNotificationsCount}
          lastNotificationsCountChangeAction={lastNotificationsCountChangeAction}
        />
      </CustomDropdown>
    </Badge>
  );
};
