/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResubmitOrderGetErxHubApprovalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.VendorOrderAcceptInput;
}>;


export type ResubmitOrderGetErxHubApprovalMutation = { __typename?: 'Mutation', resubmitOrderGetErxHubApproval?: { __typename?: 'ResubmitOrderGetErxHubApproval', orderErrors: Array<{ __typename?: 'OrderError', code: Types.OrderErrorCode, field?: string | null, message?: string | null }>, order?: { __typename?: 'Order', id: string, status: Types.OrderStatus } | null } | null };


export const ResubmitOrderGetErxHubApprovalDocument = gql`
    mutation resubmitOrderGetErxHubApproval($id: ID!, $input: VendorOrderAcceptInput!) {
  resubmitOrderGetErxHubApproval(id: $id, input: $input) {
    orderErrors {
      code
      field
      message
    }
    order {
      id
      status
    }
  }
}
    `;
export type ResubmitOrderGetErxHubApprovalMutationFn = Apollo.MutationFunction<ResubmitOrderGetErxHubApprovalMutation, ResubmitOrderGetErxHubApprovalMutationVariables>;

/**
 * __useResubmitOrderGetErxHubApprovalMutation__
 *
 * To run a mutation, you first call `useResubmitOrderGetErxHubApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubmitOrderGetErxHubApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubmitOrderGetErxHubApprovalMutation, { data, loading, error }] = useResubmitOrderGetErxHubApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResubmitOrderGetErxHubApprovalMutation(baseOptions?: Apollo.MutationHookOptions<ResubmitOrderGetErxHubApprovalMutation, ResubmitOrderGetErxHubApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResubmitOrderGetErxHubApprovalMutation, ResubmitOrderGetErxHubApprovalMutationVariables>(ResubmitOrderGetErxHubApprovalDocument, options);
      }
export type ResubmitOrderGetErxHubApprovalMutationHookResult = ReturnType<typeof useResubmitOrderGetErxHubApprovalMutation>;
export type ResubmitOrderGetErxHubApprovalMutationResult = Apollo.MutationResult<ResubmitOrderGetErxHubApprovalMutation>;
export type ResubmitOrderGetErxHubApprovalMutationOptions = Apollo.BaseMutationOptions<ResubmitOrderGetErxHubApprovalMutation, ResubmitOrderGetErxHubApprovalMutationVariables>;