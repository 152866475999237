import { z } from "zod";

export const VitalSignUpdateFormSchema = z.object({
  items: z
    .array(
      z
        .object({
          value: z
            .number()
            .or(
              z.string().refine(value => !value || /^[1-9]\d{1,2}\/[1-9]\d{1,2}$/.test(value), {
                message: "(e.g. 120/80)",
                path: [],
              })
            )
            .nullable()
            .optional(),
          hpCode: z.string().nullable().optional(),
          display: z.string().nullable().optional(),
          arabicDisplay: z.string().nullable().optional(),
        })
        .nullable()
        .optional()
    )
    .nullable()
    .optional(),
});

export type IVitalSignsUpdateFormValues = z.infer<typeof VitalSignUpdateFormSchema>;

export const vitalSignsUpdateFormDefaultValues: IVitalSignsUpdateFormValues = {
  items: [],
};
