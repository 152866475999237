/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { Box, EmptyDataIcon, Typography, useTheme } from "@toolkit/ui";
import * as echarts from "echarts";
import moment from "moment";
import { useEffect, useRef } from "react";

type BasicLineChartProps = {
  title: string;
  color?: string;
  widthValue?: number | string;
  maxRangeValue?: number;
  minRangeValue?: number;
  data?: {
    value: number;
    value2?: number;
    currentData: boolean;
    unit?: string;
    date?: string;
    source?: string;
  }[];
  data2?: {
    value: number;
    currentData: boolean;
    date?: string;
    unit?: string;
    source?: string;
  }[];
};
type MarkLineOption = {
  yAxis: number;
  lineStyle: {
    color: string;
  };
};

export const BasicLineChart = ({ title, color, data, data2, widthValue, minRangeValue, maxRangeValue }: BasicLineChartProps) => {
  const { t, i18n } = useTranslation("provider");
  const theme = useTheme();
  const chartRefLine = useRef(null);

  const dataView = data?.length
    ? data
        ?.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
        .map(item => ({
          date: moment(item?.date).locale(i18n.language).format("DD-MM-YYYY hh:mm"),
          unit: item?.unit,
          value: item.value,
          value2: item.value2,
          source: item.source,
          itemStyle: {
            borderWidth: 4,
            color: color || theme.palette.secondary.main,
            emphasis: {
              symbolSize: 20,
            },
          },
        }))
    : [];

  const _data2 = data2?.length
    ? data2
        ?.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
        .map(item => ({
          date: item?.date,
          unit: item?.unit,
          value: item.value,
          source: item.source,
          itemStyle: {
            borderWidth: 4,
            color: color || theme.palette.primary.main,
            emphasis: {
              symbolSize: 20,
            },
          },
        }))
    : [];

  const markLine: MarkLineOption[] = [];
  if (maxRangeValue && minRangeValue) {
    markLine.push(
      {
        yAxis: maxRangeValue,
        lineStyle: {
          color: theme.palette.stale.main,
        },
      },
      {
        yAxis: minRangeValue,
        lineStyle: {
          color: theme.palette.stale.main,
        },
      }
    );
  }
  const generateTooltipContent = params => {
    const value1 = data2?.length ? params?.[1]?.data?.value || "-" : params?.[0]?.data?.value || "-";
    const value2 = data2?.length ? params?.[0]?.data?.value || "" : "";
    const unit = data2?.length ? params?.[0]?.data?.unit : params?.[0]?.data?.unit || "";
    const date = data2?.length ? params?.[0]?.data?.date : params?.[0]?.data?.date || "-";
    const source = params?.[0]?.data?.source ? `${t("Source")}: ${params?.[0]?.data?.source}` : "";

    let tooltipContent = `${title}: `;
    tooltipContent += value2 ? `${value1}/${value2} ${unit}` : `${value1} ${unit}`;
    tooltipContent += ` <br/> ${t("Date")}: ${date} <br/> ${source}`;

    return tooltipContent;
  };
  const dataX = data?.length ? dataView?.map(item => `${moment(item?.date).format("DD-MM")}`) : [];

  useEffect(() => {
    if (data?.length) {
      const chart = echarts.init(chartRefLine.current);
      const options = {
        grid: {
          left: "3%",
          right: "3%",
          bottom: "4%",
          containLabel: true,
        },
        tooltip: {
          show: !!data?.length,
          trigger: "axis",
          formatter: params => generateTooltipContent(params),
          extraCssText: "box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); border: 1px solid #ccc; background-color: #fff; padding: 10px;",
        },
        xAxis: {
          show: !!data?.length,
          type: "category",
          data: dataX,
          axisLine: {
            lineStyle: {
              color: theme.palette.stale.main,
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 0,
            show: false,
          },
        },
        yAxis: {
          show: !!data?.length,
          type: "value",
          min: 0,
          axisLine: {
            fontSize: Number(widthValue) <= 200 ? 8 : 12,
            lineStyle: {
              color: theme.palette.stale.main,
            },
          },
        },
        series: [
          {
            name: "line",
            type: "line",
            data: dataView,
            lineStyle: {
              color: data?.length ? color || theme.palette.secondary.main : theme.palette.gray.light,
              smooth: false,
            },
            itemStyle: {
              borderWidth: 4,
              color: data?.length ? color || theme.palette.secondary.main : theme.palette.gray.light,
              emphasis: {
                symbolSize: 20,
              },
            },
            symbol: "circle",
            symbolSize: 5,
            markLine: {
              silent: true,
              symbol: "none",
              data: markLine,
            },
          },
          {
            name: "line",
            type: "line",
            data: _data2,
            lineStyle: {
              color: _data2?.length ? color || theme.palette.primary.main : theme.palette.stale.main,
              smooth: false,
            },
            itemStyle: {
              borderWidth: 4,
              color: _data2?.length ? color || theme.palette.primary.main : theme.palette.stale.main,
              emphasis: {
                symbolSize: 20,
              },
            },
            symbol: "circle",
            symbolSize: 5,
            markLine: {
              silent: true,
              symbol: "none",
              data: markLine,
            },
          },
        ],
      };

      chart.setOption(options);

      return () => {
        chart.dispose();
      };
    }
  }, [JSON.stringify(data), JSON.stringify(data2), color, data?.length, title, widthValue]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: widthValue || "100%",
        marginTop: -6,
        height: "210px",
      }}
    >
      <>
        {!data?.length ? (
          <Box>
            <EmptyDataIcon sx={{ width: widthValue || "100%" }} height={90} />
            <Typography fontWeight={theme.mixins.fonts.fontWeight.medium}>{t("No data available")}</Typography>
          </Box>
        ) : (
          <Box ref={chartRefLine} sx={{ width: "100%", height: "100%" }} />
        )}
      </>
    </Box>
  );
};

export default BasicLineChart;
