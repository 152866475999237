import { GraphQLErrors } from "@apollo/client/errors";
import { Lab } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { LabsDocument, useLabRequestCreateMutation, useLabRequestDeleteMutation, useLabsQuery, useSetLabResultMutation } from "../gql";

export const useLabsHook = (visitId, token) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [selectedRow, setSelectedRow] = useState<Lab | undefined>(undefined);
  const [errors, setErrors] = useState<GraphQLErrors>([]);
  const { data, loading } = useLabsQuery({
    variables: {
      visitId,
    },
    context: getApolloContextFormToken(token),
  });

  const [labRequestDelete] = useLabRequestDeleteMutation({
    onCompleted: data => {
      const labErrors = data?.labRequestDelete?.labErrors;
      if (labErrors?.length === 0) {
        succeeded(t("Lab Delete Successfully"));
      } else {
        failed(t("Deleted Lab Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [LabsDocument],
    context: getApolloContextFormToken(token),
  });

  const handleDelete = data => {
    labRequestDelete({
      variables: {
        labRequestDeleteId: data?.id!,
      },
    });
  };

  const [labRequestCreate, { loading: isSubmittingCreate }] = useLabRequestCreateMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const labErrors = data?.labRequestCreate?.labErrors;
      if (labErrors?.length === 0) {
        succeeded(t("Lab Added Successfully"));
      } else {
        failed(t("Added Lab Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      setErrors(graphQLErrors!);
    },
    refetchQueries: [LabsDocument],
  });

  const [setLabResult, { loading: isSetResultCreate }] = useSetLabResultMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const labErrors = data?.setLabResult?.labErrors;
      if (labErrors?.length === 0) {
        succeeded(t("Lab Result Added Successfully"));
        setSelectedRow(undefined);
      } else {
        failed(t("Added Lab Result Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      setErrors(graphQLErrors!);
    },
    refetchQueries: [LabsDocument],
  });

  const handleCreate = input => {
    labRequestCreate({
      variables: {
        input: {
          visitId: visitId,
          code: input?.code?.value?.code,
        },
      },
    });
  };
  const handleSetLabResult = inputResult => {
    const result = {
      a1c: inputResult?.a1c,
      acr: inputResult?.acr,
      abi: inputResult?.abi,
      pbh: inputResult?.pbh,
      pbl: inputResult?.pbl,
      bmd: inputResult?.bmd,
      bmi: inputResult?.bmi,
      retinopathy: inputResult?.retinopathy,
      egfr: inputResult?.egfr,
      ldl: inputResult?.ldl,
      cvd: inputResult?.cvd,
      // frequency_of_visit: inputResult?.frequency_of_visit,
    };
    const resultFilter = Object.entries(result).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

    setLabResult({
      variables: {
        input: {
          visitId: visitId!,
          code: selectedRow?.code!,
          result: JSON.stringify(resultFilter),
        },
      },
    });
  };
  const handleEdit = row => {
    setSelectedRow(row);
  };

  const labs = data?.visit?.labs as Lab[];

  return {
    labs,
    loading,
    errors,
    selectedRow,
    isSetResultCreate,
    isSubmittingCreate,
    handleCreate,
    handleDelete,
    handleEdit,
    handleSetLabResult,
  };
};
