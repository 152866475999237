import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, useModal } from "@toolkit/ui";
import { MedicalFormClassificationUpsertModal } from "../../../MedicalForms/modals/MedicalFormClassificationUpsert/MedicalFormClassificationUpsertModal";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useMedicalFormClassificationsFormStyle } from "./useMedicalFormClassificationsFormStyle";
import { useMedicalFormClassificationsTableColumns } from "./useMedicalFormClassificationsTableColumns";
export const MedicalFormClassificationsForm = () => {
    const [selectedClassification, setSelectedClassification] = useState();
    const { t } = useTranslation("domains");
    const { open, handleOpen, handleClose } = useModal();
    const { classes, theme } = useMedicalFormClassificationsFormStyle();
    const form = useCustomFormContext();
    const { control } = form;
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "medicalFormClassifications",
    });
    const onAddClick = () => {
        setSelectedClassification(undefined);
        handleOpen();
    };
    const onEditRowClick = (item) => {
        setSelectedClassification(item);
        handleOpen();
    };
    const onDeleteRowClick = (item) => {
        const itemIndex = fields.findIndex(field => field.id === item.id);
        if (itemIndex > -1) {
            remove(itemIndex);
        }
    };
    const onMedicalFormClassificationUpsertModalChange = (event) => {
        if (event.type === "CREATE") {
            append(event.payload.values);
            handleClose();
        }
        else if (event.type === "UPDATE" && selectedClassification) {
            const updatedFieldIndex = fields.findIndex(item => item.id === selectedClassification.id);
            if (updatedFieldIndex > -1) {
                const updatedTeaMember = Object.assign(Object.assign({}, fields[updatedFieldIndex]), event.payload.values);
                update(updatedFieldIndex, updatedTeaMember);
            }
            handleClose();
        }
    };
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(MedicalFormClassificationUpsertModal, { isOpen: open, onClose: handleClose, classification: selectedClassification, onChange: onMedicalFormClassificationUpsertModalChange }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Grid, Object.assign({ container: true, justifyContent: "space-between" }, { children: [_jsx(Grid, { children: _jsxs(Typography, Object.assign({ className: classes.title }, { children: [" ", t("Classifications"), " "] })) }), _jsx(Grid, { children: _jsx(Button, Object.assign({ fullWidth: true, onClick: onAddClick, startIcon: _jsx(PlusIcon, { fill: theme.palette.common.white }) }, { children: t("Add Classification") })) })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(CustomTable, { data: fields, columns: useMedicalFormClassificationsTableColumns(), isEditVisible: true, isDeleteVisible: true, isRowEditable: true, isRowDeletable: true, onEditRow: onEditRowClick, onDeleteRow: onDeleteRowClick, hasFooter: false, scrollable: true }) }))] })));
};
