import { HealthProgram, HealthProgramMember, Maybe } from "@health/queries/types";
import { Box, Button, CheckMarkIcon, CustomDialog, Divider, IconButton, Stack, Tooltip, Typography } from "@toolkit/ui";
import {
  AppointmentAcceptPaymentInformation,
  AppointmentDate,
  AvailableTimeSlots,
  InsuranceInformation,
} from "pages/appointments/components";
import React, { FC, ReactElement } from "react";
import { NotificationItemAction } from "shared/components";
import { useAppointmentAccept } from "./useAppointmentAccept";
import { useAppointmentAcceptStyle } from "./useAppointmentAcceptStyle";

type AppointmentAcceptProps = {
  id: string;
  requestedTimeSlots?: Maybe<Maybe<string>[]>;
  activeHealthProgramMember?: Maybe<Maybe<HealthProgramMember>>;
  healthProgramsByActiveMember?: Maybe<Maybe<HealthProgram>>;
  buttonEl?: ReactElement;
} & NotificationItemAction;

export const AppointmentAccept: FC<AppointmentAcceptProps> = props => {
  const { id, activeHealthProgramMember, healthProgramsByActiveMember, requestedTimeSlots, buttonEl, handleNotificationActionComplete } =
    props;

  const {
    t,
    patientShareAmount,
    selectedDate,
    timeSlots,
    open,
    handleToggle,
    isExpiredDate,
    errors,
    selectedTimeSlot,
    onSubmit,
    isSubmitting,
    handlePatientShareAmountChange,
    handleSelectTimeSlot,
    handleChangeDate,
  } = useAppointmentAccept({ id, requestedTimeSlots, handleNotificationActionComplete });

  const { classes } = useAppointmentAcceptStyle();

  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Appointment accepted"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={isExpiredDate ? t("The Date for this Appointment is Expired") : ""}>
          <span>
            {buttonEl ? (
              React.cloneElement(buttonEl, { disabled: isExpiredDate, onMouseDown: handleToggle })
            ) : (
              <IconButton disabled={isExpiredDate} onClick={handleToggle}>
                <CheckMarkIcon color='success' />
              </IconButton>
            )}
          </span>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Tooltip title={!selectedTimeSlot ? t("Please select time slot") : ""}>
            <span>
              <Button color='success' onClick={onSubmit} disabled={!selectedTimeSlot || isSubmitting}>
                {t("Accept")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      <Box>
        {errors && (
          <Typography color='error' paragraph>
            {t("Please fill the form")}
          </Typography>
        )}

        <Stack
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={2}
          divider={<Divider sx={{ width: "100%" }} />}
        >
          <Box className={classes.expiredDateWrapper}>
            <AppointmentDate isDisable date={selectedDate} onChangeDate={handleChangeDate} />

            {isExpiredDate && (
              <Typography color='error' className={classes.expiredDateMessage}>
                {t("The Date for this Appointment is Expired")}
              </Typography>
            )}
          </Box>

          <AvailableTimeSlots availableTimeSlots={timeSlots} selectedTimeSlots={[selectedTimeSlot!]} onSelect={handleSelectTimeSlot} />

          <InsuranceInformation
            activeHealthProgramMember={activeHealthProgramMember}
            healthProgramsByActiveMember={healthProgramsByActiveMember}
          />

          <AppointmentAcceptPaymentInformation
            appointmentId={id}
            patientShareAmount={patientShareAmount}
            handlePatientShareAmountChange={handlePatientShareAmountChange}
          />
        </Stack>
      </Box>
    </CustomDialog>
  );
};
