import { IEnumToOptionsMap } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";

export enum GuidedCareTemplateInterventionSteps {
  Interventions = "INTERVENTIONS",
  Activities = "ACTIVITIES",
}

export const guidedCareTemplateInterventionStepsOptionsMap: IEnumToOptionsMap<GuidedCareTemplateInterventionSteps> = {
  [GuidedCareTemplateInterventionSteps.Interventions]: {
    key: GuidedCareTemplateInterventionSteps.Interventions,
    label: i18n.t("Interventions", { ns: "provider" }),
    value: GuidedCareTemplateInterventionSteps.Interventions,
  },
  [GuidedCareTemplateInterventionSteps.Activities]: {
    key: GuidedCareTemplateInterventionSteps.Activities,
    label: i18n.t("Activities", { ns: "provider" }),
    value: GuidedCareTemplateInterventionSteps.Activities,
  },
};

export const guidedCareTemplateInterventionStepsOptions = Object.values(guidedCareTemplateInterventionStepsOptionsMap);
