import { Order } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useOrderPageContext } from "../state";
import { mapOrderToActionByStatus, mapOrderToColumnByStatus } from "./mapper";

export const useOrderMapper = () => {
  const { t } = useTranslation("provider");
  const { time } = useOrderPageContext();

  const mapOrderToData = (order: Partial<Order>) => mapOrderToColumnByStatus(order, time, t);
  const mapOrderToAction = (order: Partial<Order>) => mapOrderToActionByStatus(order, time, t);

  return {
    mapOrderToData,
    mapOrderToAction,
  };
};
