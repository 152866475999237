import { makeStyles } from "tss-react/mui";

type HealthParameterStethoscopeAndOtoscopeStyleProps = {
  height: number;
};

export const useHealthParameterStethoscopeAndOtoscopeStyle = makeStyles<HealthParameterStethoscopeAndOtoscopeStyleProps>()(
  (theme, props) => ({
    root: {
      width: "100%",
      height: props.height,
      borderRadius: "10px",
      background: theme.palette.common.white,
      boxShadow: theme.mixins.shadows.main,
      padding: "5px 10px",
    },
    infoWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontSize: theme.mixins.fonts.fontSize.md,
      fontWeight: theme.mixins.fonts.fontWeight.medium,
      color: theme.palette.primary.main,
      whiteSpace: "nowrap",
      marginBottom: 5,
    },
    date: {
      fontSize: theme.mixins.fonts.fontSize.xs,
      color: theme.palette.stale.main,
      whiteSpace: "nowrap",
    },
    source: {
      fontSize: theme.mixins.fonts.fontSize.sm,
      color: theme.palette.stale.main,
    },
  })
);
