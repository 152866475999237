import { MarketplaceApprovalStatus } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { useToggle } from "shared/hooks";
import { MarketplaceProductPublishStatusUpdateMutation, useMarketplaceProductPublishStatusUpdateMutation } from "../gql";
import { ProductsQueryNode } from "../types";

export const ToggleProductsPublishStatus: FC<{ row: ProductsQueryNode }> = ({ row }) => {
  const [checked, setChecked] = useState<boolean>(row?.isPublished);
  const { t } = useTranslation("provider");
  const { handleToggle, open } = useToggle();
  const { succeeded, failed } = useAddToast();
  const [productsPublishStatusUpdateMutation, { loading }] = useMarketplaceProductPublishStatusUpdateMutation({
    onCompleted: (data: MarketplaceProductPublishStatusUpdateMutation, options) => {
      const responseData = data?.[options?.variables?.isPublish ? "publishMarketplaceProduct" : "unPublishMarketplaceProduct"];
      if (responseData?.id) {
        setChecked(responseData?.isPublished as boolean);
        succeeded(!checked ? t("Product published successfully!") : t("Product is now unpublished"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handlePublishPackage = () => {
    productsPublishStatusUpdateMutation({
      variables: {
        id: row?.id,
        isPublish: !checked,
      },
    });
  };

  useEffect(() => {
    setChecked(row?.isPublished);
  }, [row?.isPublished]);

  const isPublishDisabled = row?.approvalStatus !== MarketplaceApprovalStatus.Approved;
  return (
    <>
      <CustomToggleButton
        disabled={loading || isPublishDisabled || !row?.updatable}
        checked={checked}
        onChange={handleToggle}
        isLoading={loading}
      />
      {open && (
        <CustomDialog
          type='warning'
          isOpen={open}
          text={{
            body: checked ? t("Are you sure you want to un publish this product?") : t("Are you sure you want to publish this  product?"),
          }}
          onConfirm={handlePublishPackage}
          onClose={handleToggle}
        />
      )}
    </>
  );
};
