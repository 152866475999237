import { useReactiveVar } from "@apollo/client";
import { useOnAppointmentStatusUpdate } from "@health/common";
import { isNotificationMenuOpenedVar } from "@toolkit/ui";
import { useAppointmentQuery } from "../gql";

export const useAppointmentNotificationHook = (id, isDisplayedInDropDown) => {
  const isOpen = useReactiveVar(isNotificationMenuOpenedVar);
  const skipIfDropdownIsClosed = isDisplayedInDropDown ? !isOpen : false;
  const { data, loading, refetch } = useAppointmentQuery({
    variables: {
      id,
    },
    skip: !id || skipIfDropdownIsClosed,
  });

  const appointment = data?.appointment;
  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id === id) {
      refetch();
    }
  });

  return {
    loading,
    appointment,
  };
};
