/* eslint-disable sonarjs/cognitive-complexity */
import { guidedCareActivityTypeMedicalMessageOptions, guidedCareActivityTypeOptions } from "@health/enum-options";
import { GuidedCareJourneyItem } from "@health/queries/types";
import moment from "moment";
import { useEffect, useState } from "react";
import { JourneyActivityFilter, JourneyActivityScope, JourneyDurationPeriod } from "shared/modules/patient/types";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../context/ProfileContext";
import { useGetDoctorIdQuery, useTimelineItemsLazyQuery } from "../../../gql";

export const useAnnualTimeLineHooks = ({ source, duration, selectedMe, selectedActivities, selectedTeamMemberPosition, onSelectItem }) => {
  const { token, patientId, loading: loadingData, selectedHealthProgram } = useProfileContext();
  const formatDate = "YYYY-MM-DD";
  const currentMonth = moment().format("MMM");
  const n = moment.monthsShort().indexOf(currentMonth);
  const step = Math.ceil(n / 3.0) * 3 === 12 ? 9 : Math.ceil(n / 3.0) * 3;
  const [year, setYear] = useState(moment().year());

  const { data: dataMe, loading: loadingMe } = useGetDoctorIdQuery({
    context: getApolloContextFormToken(token!),
    fetchPolicy: "no-cache",
  });
  const doctorId = dataMe?.me?.doctor?.id!;

  const types = guidedCareActivityTypeOptions?.map(item => item?.value);
  const medicalMessagesTypes = guidedCareActivityTypeMedicalMessageOptions?.map(item => item?.value);

  const [getGuidedCareJourneyItems, { data: guidedCareJourneyItems, loading: loadingItems }] = useTimelineItemsLazyQuery({
    context: getApolloContextFormToken(token!),
    variables: {
      first: 10,
      filter: {
        patientId: patientId!,
        guidedCareHealthProgramId: selectedHealthProgram,
      },
    },
  });

  useEffect(() => {
    if (!patientId) return;

    const firstDay = moment().year(year).startOf("year").format(formatDate);
    const lastDay = moment().year(year).endOf("year").format(formatDate);
    getGuidedCareJourneyItems({
      variables: {
        first: 100,
        filter: {
          patientId: patientId,
          source,
          dueDate: { gte: firstDay, lte: lastDay },
          teamMemberPosition: selectedTeamMemberPosition,
          doctorId: selectedMe === JourneyActivityScope.MyActivities ? doctorId : null,
          guidedCareHealthProgramId: selectedHealthProgram,
          types: selectedActivities === JourneyActivityFilter.ShowAll ? types : types.filter(item => !medicalMessagesTypes.includes(item)),
        },
      },
    });
  }, [patientId, year, duration, selectedTeamMemberPosition, selectedMe, source, selectedActivities, selectedHealthProgram]);

  const loading = loadingData || loadingItems || loadingMe;

  const guidedCareJourneyActivities =
    (guidedCareJourneyItems?.timelineItems?.edges?.map(item => item?.node) as GuidedCareJourneyItem[]) || [];

  const monthsList = moment.monthsShort();

  const getActivities = (month: string) => guidedCareJourneyActivities?.filter(item => moment(item?.dueDate).format("MMM") === month);

  const getActivitiesWeeks = (month: string) =>
    getActivities(month).map(item => {
      const weekNumber = moment(item.dueDate).isoWeek();
      const weekNumberInCurrentMonth = weekNumber - moment(item.dueDate).startOf("month").isoWeek();
      return { ...item, weekNumberInCurrentMonth };
    });

  const timeline = monthsList.map(month => ({
    label: month.toUpperCase(),
    activities: getActivities(month),
    activitiesWeeks: getActivitiesWeeks(month),
  }));

  const timelineList = timeline?.map(item => {
    const appointmentsW1 = item.activitiesWeeks.filter(activityWeek => activityWeek?.weekNumberInCurrentMonth === 0);
    const appointmentsW2 = item.activitiesWeeks.filter(activityWeek => activityWeek?.weekNumberInCurrentMonth === 1);
    const appointmentsW3 = item.activitiesWeeks.filter(activityWeek => activityWeek?.weekNumberInCurrentMonth === 2);
    const appointmentsW4 = item.activitiesWeeks.filter(activityWeek => activityWeek?.weekNumberInCurrentMonth >= 3);
    return {
      ...item,
      activitiesWeeks: {
        activitiesWeeks1: appointmentsW1,
        activitiesWeeks2: appointmentsW2,
        activitiesWeeks3: appointmentsW3,
        activitiesWeeks4: appointmentsW4,
      },
    };
  });

  const handleIncrementYear = () => {
    if (duration === JourneyDurationPeriod.Annual) {
      setYear(prevState => prevState + 1);
    }
    onSelectItem(undefined);
  };

  const handleDecrementYear = () => {
    if (duration === JourneyDurationPeriod.Annual) {
      setYear(prevState => prevState - 1);
    }
    onSelectItem(undefined);
  };
  const filterInput = {
    patientId,
    source,
    teamMemberPosition: selectedTeamMemberPosition,
    doctorId: selectedMe === JourneyActivityScope.MyActivities ? doctorId : null,
    guidedCareHealthProgramId: selectedHealthProgram,
    types: selectedActivities === JourneyActivityFilter.ShowAll ? types : types.filter(item => !medicalMessagesTypes.includes(item)),
  };

  return {
    year,
    loading,
    step,
    filterInput,
    timeline: timelineList,
    handleIncrementYear,
    handleDecrementYear,
  };
};
