import { useState } from "react";
export const useOpenStateController = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleToggle = () => {
        setOpen(openState => !openState);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return { open, handleOpen, handleClose, handleToggle };
};
