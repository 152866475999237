import { useOnPatientProfileAccessResponse, usePatientProfileAccessClose } from "@health/common";
import { PatientHealthHistory } from "@health/queries/types";
import { useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { usePatientMedicalProfileLazyQuery } from "../gql";

export const useCustomPatientMedicalProfileQuery = ({ token, patientId }) => {
  const [hasAccess, setCheckAccess] = useState<boolean>(false);
  const [consentStatus, setConsentStatus] = useState<string | undefined>(undefined);

  const [getPatientMedicalProfileQuery, { data: patientMedicalProfileQueryResult, loading: patientMedicalProfileQueryLoading }] =
    usePatientMedicalProfileLazyQuery({
      onCompleted: () => {
        setCheckAccess(true);
      },
      onError: () => {
        setCheckAccess(false);
      },
      context: getApolloContextFormToken(token!),
    });

  useOnPatientProfileAccessResponse(({ data }) => {
    if (data?.patientProfileConsentFormId) {
      getPatientMedicalProfileQuery({
        variables: {
          patientId: patientId!,
        },
      });
      setConsentStatus(data?.consentStatus);
    }
  });
  usePatientProfileAccessClose(({ data }) => {
    if (data?.patientProfileConsentFormId) {
      getPatientMedicalProfileQuery({
        variables: {
          patientId: patientId!,
        },
        fetchPolicy: "no-cache",
      });
      setConsentStatus(data?.consentStatus);
    }
  });

  const handleResetStatus = () => {
    setConsentStatus(undefined);
    setCheckAccess(true);
  };

  const patientCurrentMedicalStatus = patientMedicalProfileQueryResult?.patientMedicalProfile?.patientCurrentMedicalStatus!;
  const patientHealthHistory = patientMedicalProfileQueryResult?.patientMedicalProfile?.patientHealthHistory as PatientHealthHistory[];

  return {
    patientCurrentMedicalStatus,
    patientHealthHistory,
    hasAccess,
    consentStatus,
    handleResetStatus,
    patientMedicalProfileQueryLoading,
    getPatientMedicalProfileQuery,
  };
};
