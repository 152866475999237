import { useTranslation } from "@toolkit/i18n";
import { Box, Divider } from "@toolkit/ui";
import { SectionTitle, TimeSlotWrapper } from "pages/appointments/components";
import { IFormattedTimeSlot, IGroupedAvailableTimeSlots } from "pages/appointments/types/types";
import React, { FC } from "react";
import { useAvailableTimeSlotsStyle } from "./useAvailableTimeSlotsStyle";

type AvailableTimeSlotsProps = {
  isMulti?: boolean;
  selectedTimeSlots: IFormattedTimeSlot[];
  availableTimeSlots: IGroupedAvailableTimeSlots;
  onSelect: (time: IFormattedTimeSlot) => void;
};

export const AvailableTimeSlots: FC<AvailableTimeSlotsProps> = props => {
  const { isMulti, availableTimeSlots, selectedTimeSlots, onSelect } = props;

  const { t } = useTranslation("provider");

  const { classes } = useAvailableTimeSlotsStyle();

  return (
    <Box className={classes.root}>
      <SectionTitle title={t(`Select a slot ${isMulti ? "(multi select)" : ""}`)} />

      <Box className={classes.timeSlotsWrapper}>
        <TimeSlotWrapper
          label={t("Morning")}
          timeSlots={availableTimeSlots?.morningSlots}
          selectedTimeSlots={selectedTimeSlots}
          onSelect={item => onSelect(item)}
        />

        <Divider className={classes.divider} />

        <TimeSlotWrapper
          label={t("Afternoon")}
          timeSlots={availableTimeSlots?.afternoonSlots}
          selectedTimeSlots={selectedTimeSlots}
          onSelect={item => onSelect(item)}
        />

        <Divider className={classes.divider} />

        <TimeSlotWrapper
          label={t("Evening")}
          timeSlots={availableTimeSlots?.eveningSlots}
          selectedTimeSlots={selectedTimeSlots}
          onSelect={item => onSelect(item)}
        />
      </Box>
    </Box>
  );
};
