import { useVisitsVitalSignsChanged } from "@health/common";
import { useTranslation } from "@toolkit/i18n";
import { CircularProgress, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useVisitVitalSignsChangePercentageQuery } from "shared/modules/patient/gql";
import { getApolloContextFormToken } from "shared/utils";
import { getVitalSignsChangePercentage, vitalSignsCode } from "../../utils";
import { VitalSignBloodPressureChart } from "../../VitalSignBloodPressureChart";
import { VitalSignDialog } from "../../VitalSignDialog";
import { VitalSignHeartRateChart } from "../../VitalSignHeartRateChart";
import { VitalSignOxygenSaturationChart } from "../../VitalSignOxygenSaturationChart";
import { VitalSignRespirationRateChart } from "../../VitalSignRespirationRateChart";
import { VitalSignTemperatureChart } from "../../VitalSignTemperatureChart";

type VitalSignsChartsProps = {
  patientId: string;
  token: string;
  gridWidth?: number;
};

export const VitalSignsCharts: FC<VitalSignsChartsProps> = props => {
  const { patientId, token, gridWidth = 6 } = props;

  const { t } = useTranslation("provider");

  const {
    data: visitVitalSignsChangePercentageData,
    loading: loadingVisitVitalSignsChangePercentage,
    refetch: refetchPercentage,
  } = useVisitVitalSignsChangePercentageQuery({
    variables: {
      patientId: patientId!,
    },
    context: getApolloContextFormToken(token!),
  });

  const vitalSignsChangePercentage = getVitalSignsChangePercentage(visitVitalSignsChangePercentageData?.visitVitalSignsChangePercentage!);

  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.patientId) {
      refetchPercentage({ patientId: patientId! });
    }
  });

  return loadingVisitVitalSignsChangePercentage ? (
    <CircularProgress size='20px' color='inherit' />
  ) : (
    <>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog display={t("Heart Rate")} codes={[vitalSignsCode.heartRate]} token={token!} patientId={patientId!}>
          <VitalSignHeartRateChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.heartRate}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog
          display={t("Blood Pressure")}
          token={token!}
          patientId={patientId!}
          codes={[vitalSignsCode.bloodPressureHigh, vitalSignsCode.bloodPressureLow]}
        >
          <VitalSignBloodPressureChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.bloodPressureLow || vitalSignsChangePercentage?.bloodPressureHigh}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog display={t("Respiration Rate")} token={token!} patientId={patientId!} codes={[vitalSignsCode.respiratoryRate]}>
          <VitalSignRespirationRateChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.respiratoryRate}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={gridWidth}>
        <VitalSignDialog display={t("Temperature")} token={token!} patientId={patientId!} codes={[vitalSignsCode.temperature]}>
          <VitalSignTemperatureChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.temperature}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={gridWidth}>
        <VitalSignDialog display={t("Oxygen Saturation")} token={token!} patientId={patientId!} codes={[vitalSignsCode.oxygenSaturation]}>
          <VitalSignOxygenSaturationChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.oxygenSaturation}
          />
        </VitalSignDialog>
      </Grid>
    </>
  );
};
