import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import GeneralSettings from "pages/Settings/GeneralSettings";
import AppointmentsRemindersSettings from "./AppointmentsRemindersSettings";
import { SettingsHeader } from "./components";
import { useSettingsPage } from "./useSettingsPage";

export const SettingsPage: React.FC = () => {
  const { selectedTab, handleChangeTab } = useSettingsPage();
  const shouldShowRemindersTab = hasPermission(PermissionEnum.ManageSettings);

  return (
    <>
      <SettingsHeader selectedTab={selectedTab} onChangeTab={handleChangeTab} shouldShowRemindersTab={shouldShowRemindersTab} />
      {selectedTab === "AppointmentsReminder" && shouldShowRemindersTab && <AppointmentsRemindersSettings />}
      {selectedTab === "General" && <GeneralSettings />}
    </>
  );
};

export default SettingsPage;
