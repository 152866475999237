import { useOpenStateController } from "@health/common";
import { useTranslation } from "@toolkit/i18n";
import { Button } from "@toolkit/ui";
import { FC } from "react";
import { QRCodeModal } from "shared/components";

export const QRCodeButton: FC<{ id: string; name: string }> = ({ id, name }) => {
  const { t } = useTranslation("provider");
  const { open, handleOpen, handleClose } = useOpenStateController();

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          fontSize: 13,
          whiteSpace: "nowrap",
        }}
      >
        {t("View QR")}
      </Button>
      <QRCodeModal
        title={`${t("Department Name")}: ${name}`}
        printTitle={t("Check-in for your appointment by scanning the QR code.")}
        qrCode={{ app: "ioHealth", version: 1, type: "department", typeId: id, typeLabel: name }}
        withPrint
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};
