import { useTranslation } from "@toolkit/i18n";
import { Typography } from "@toolkit/ui";
import { FC } from "react";
import { mapStatusToColor } from "../statuses";
import { useColumnHeaderStyles } from "./ColumnHeader.styles";

type ColumnHeaderProps = {
  name: string;
  count: number;
  status: string;
};

export const ColumnHeader: FC<ColumnHeaderProps> = props => {
  const { name, count, status } = props;
  const color = mapStatusToColor[status];
  const { i18n } = useTranslation("provider");
  const { classes } = useColumnHeaderStyles({ color });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography className={classes.name}>{name}</Typography>
        <div className={classes.count}>
          {Intl.NumberFormat(i18n?.language, {
            notation: "compact",
            maximumFractionDigits: 2,
          }).format(count)}
        </div>
      </div>
    </div>
  );
};
