/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitsVitalSignsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.VisitVitalSignsFilterInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type VisitsVitalSignsQuery = { __typename?: 'Query', visitsVitalSigns: { __typename?: 'VisitVitalSignsCountableConnection', edges: Array<{ __typename?: 'VisitVitalSignsCountableEdge', node: { __typename?: 'VisitVitalSigns', id: string, modified?: any | null, source?: Types.Source | null, hpCode?: string | null, valueNumber?: any | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const VisitsVitalSignsDocument = gql`
    query visitsVitalSigns($first: Int, $filter: VisitVitalSignsFilterInput, $before: String, $after: String, $last: Int) {
  visitsVitalSigns(
    first: $first
    filter: $filter
    before: $before
    after: $after
    last: $last
  ) {
    edges {
      node {
        id
        modified
        source
        hpCode
        valueNumber
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useVisitsVitalSignsQuery__
 *
 * To run a query within a React component, call `useVisitsVitalSignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitsVitalSignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitsVitalSignsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useVisitsVitalSignsQuery(baseOptions?: Apollo.QueryHookOptions<VisitsVitalSignsQuery, VisitsVitalSignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitsVitalSignsQuery, VisitsVitalSignsQueryVariables>(VisitsVitalSignsDocument, options);
      }
export function useVisitsVitalSignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitsVitalSignsQuery, VisitsVitalSignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitsVitalSignsQuery, VisitsVitalSignsQueryVariables>(VisitsVitalSignsDocument, options);
        }
export type VisitsVitalSignsQueryHookResult = ReturnType<typeof useVisitsVitalSignsQuery>;
export type VisitsVitalSignsLazyQueryHookResult = ReturnType<typeof useVisitsVitalSignsLazyQuery>;
export type VisitsVitalSignsQueryResult = Apollo.QueryResult<VisitsVitalSignsQuery, VisitsVitalSignsQueryVariables>;