import { useTranslation } from "@toolkit/i18n";
import { TableGrid } from "@toolkit/ui";
import { H_OrderDirection, PatientHealthParameterSortField } from "@health/queries/types";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { usePatientHealthParametersQuery } from "shared/modules/patient/gql";
import { getApolloContextFormToken } from "shared/utils";
import { usePatientHealthParameterStethoscopeAndOtoscopeListColumns } from "./usePatientHealthParameterStethoscopeAndOtoscopeListColumns";

type PatientHealthParameterStethoscopeAndOtoscopeListProps = {
  token: string;
  code: string;
  visitId?: string;
  patientId: string;
  selectedUrl: string;
  onChange: (valueAudioUrl: string) => void;
};

export const PatientHealthParameterStethoscopeAndOtoscopeList: FC<PatientHealthParameterStethoscopeAndOtoscopeListProps> = props => {
  const { token, code, patientId, visitId, selectedUrl, onChange } = props;

  const { t } = useTranslation("provider");

  return (
    <GridProvider
      gridName={"patientHealthParameterStethoscopeAndOtoscopeList"}
      columns={usePatientHealthParameterStethoscopeAndOtoscopeListColumns({ t, selectedUrl, onChange })}
      query={usePatientHealthParametersQuery}
      filterInput={{
        visitId,
        patientId: [patientId],
        codes: [code],
      }}
      variables={{
        sortBy: {
          field: PatientHealthParameterSortField.CreatedDate,
          direction: H_OrderDirection.Desc,
        },
      }}
      context={getApolloContextFormToken(token!)}
    >
      <TableGrid />
    </GridProvider>
  );
};
