import { OrderTypesEnum } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { DeliveredButton } from "./DeliveredButton";
import { DispenseButton } from "./DispenseButton";
import { GetApprovalButton } from "./GetApprovalButton";
import { useOrderActionStyles } from "./OrderAction.styles";
import { OrderCancelButton } from "./OrderCancelButton";
import { PickupVerificationButton } from "./PickupVerificationButton";
import { RejectReasonButton } from "./RejectReasonButton";
import { ResubmitButton } from "./ResubmitButton";
import { ViewAndAcceptButton } from "./ViewAndAcceptButton";
import { ViewQRButton } from "./ViewQRButton";
import { IOrderActions } from "./types";

export const OrderActions: FC<IOrderActions> = ({ data }) => {
  const { t } = useTranslation("provider");
  const { classes } = useOrderActionStyles();

  const { products, referenceNumber, patientIdentifier, medications, type, id, number, status, patientName, patientMobile, action } = data;
  const hasAction = Object.values(action!).some(value => value);
  return (
    <>
      {hasAction && (
        <div className={classes.buttons}>
          {action?.hasAcceptAndViewButton && (
            <>
              <ViewAndAcceptButton
                products={products}
                referenceNumber={referenceNumber}
                patientIdentifier={patientIdentifier!}
                medications={medications!}
                orderId={id!}
              />
              <RejectReasonButton id={id!} />
            </>
          )}
          {action?.hasResubmitButton && (
            <ResubmitButton
              status={status!}
              orderId={id!}
              referenceNumber={referenceNumber}
              patientIdentifier={patientIdentifier!}
              medications={medications!}
            />
          )}
          {action?.hasApproval && <GetApprovalButton id={id!} />}
          {action?.hasDispenseButton && <DispenseButton id={id!} submitLabel={t("Dispense eRx")} />}
          {action?.hasCancelButton && (
            <OrderCancelButton id={String(id)} patientName={String(patientName)} patientMobile={patientMobile!} />
          )}
          {action?.hasViewQRButton && (
            <>
              <ViewQRButton number={Number(number)} type={type} />
              {type === OrderTypesEnum.PickupFromPharmacy && <PickupVerificationButton id={id!} />}
            </>
          )}
          {action?.hasReadyForDeliveryButton && <DispenseButton id={id!} submitLabel={t("Ready for Delivery")} />}
          {action?.hasViewQRWithAWBButton && <ViewQRButton type={type} hasAwb number={Number(number)} />}
          {action?.hasDeliveredButton && <DeliveredButton id={id!} />}
        </div>
      )}
    </>
  );
};
