import { useOnAppointmentStatusUpdate } from "@health/common";
import { AppointmentFilterInput } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, useGridContext } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { CustomizedGridTableProps } from "../../types/DashboardGrid";

export const ComingSoonAppointmentsCustomizedGrid: FC<CustomizedGridTableProps<AppointmentFilterInput>> = props => {
  const { filterInput, ...rest } = props;
  const { t } = useTranslation("provider");
  const { customTableProps, refetch } = useGridContext();

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id) {
      refetch({
        ...filterInput,
        confirmedStartTime: { gte: moment().format() },
      });
    }
  });

  return (
    <>
      {customTableProps && (
        <CustomTable
          {...customTableProps}
          TableContainerProps={{
            sx: {
              height: 250,
            },
          }}
          withoutDataMessage={t("No Upcoming Appointments")}
          emptyIconHeight={70}
          iconBoxProps={{
            marginBottom: "15px",
            height: "70px",
          }}
          {...rest}
        />
      )}
    </>
  );
};
