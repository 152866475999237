import { IEnumToOptionsMap } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { JourneyActivityScope } from "shared/modules/patient/types";

export const journeyActivityScopeOptionsMap: IEnumToOptionsMap<JourneyActivityScope> = {
  [JourneyActivityScope.AllActivities]: {
    key: JourneyActivityScope.AllActivities,
    label: i18n.t("All Activities", { ns: "provider" }),
    value: JourneyActivityScope.AllActivities,
  },
  [JourneyActivityScope.MyActivities]: {
    key: JourneyActivityScope.MyActivities,
    label: i18n.t("My Activities", { ns: "provider" }),
    value: JourneyActivityScope.MyActivities,
  },
};

export const journeyActivityScopeOptions = Object.values(journeyActivityScopeOptionsMap);
