import { useState } from "react";
type IModel = {
  open: boolean;
  handleToggle: () => void;
};

export const useModal = (defaultOpen = false): IModel => {
  const [open, setOpen] = useState(defaultOpen || false);

  const handleToggle = (): void => {
    setOpen(!open);
  };

  return {
    open,
    handleToggle,
  };
};
