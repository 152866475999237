/* eslint-disable max-lines */
import { appointmentTypeOptionsMap } from "@health/enum-options";
import { Appointment } from "@health/queries/types";
import { DATE_FORMAT_FOR_TABLE_LONG, convertToTitleCase } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import {
  Box,
  CustomTableColumnProps,
  Grid,
  IconButton,
  RescheduleIcon,
  TruncateTypography,
  Typography,
  XIcon,
  format,
  useTheme,
} from "@toolkit/ui";
import { AppointmentAccept, AppointmentReject, AppointmentReschedule } from "pages/appointments";
import { safeJsonParseWithDefault } from "pages/dashboard/utils";
import { useMemo } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { PatientProfile } from "shared/modules/patient";
import { isVendorAdmin } from "utils";

export const usePendingAppointmentsGridColumns = (): CustomTableColumnProps<Appointment>[] => {
  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();
  const isVendorAdminUser = isVendorAdmin(userInfo);
  const theme = useTheme();
  return useMemo(
    () => [
      {
        key: "id",
        header: t("ID"),
        accessor: ({ id }) => <Typography sx={{ maxWidth: "100px", width: "100px" }}>{id}</Typography>,
      },
      {
        key: "Doctor",
        header: t("Doctor Name"),
        accessor: ({ doctor }) => (
          <TruncateTypography
            sx={{ cursor: "string", width: "max-content" }}
            fontSize={theme.mixins.fonts.fontSize.sm}
            color={theme.palette.secondary.main}
            text={doctor?.user?.fullName!}
          />
        ),
        isHidden: !isVendorAdminUser,
      },
      {
        key: "Patient Name",
        header: t("Patient Name"),
        accessor: ({ consumer }) => (
          <PatientProfile
            patientId={consumer?.id}
            patientName={{
              firstName: consumer?.firstName,
              lastName: consumer?.lastName,
            }}
          />
        ),
      },
      {
        key: "confirmedStartTime",
        header: t("Appointment Date"),
        accessor: ({ requestedTimeSlots }) => (
          <Box sx={{ minWidth: "max-content" }}>
            {format(
              safeJsonParseWithDefault(requestedTimeSlots?.[0]!, { startTime: "-" })?.startTime,
              DATE_FORMAT_FOR_TABLE_LONG,
              i18n.language
            )}
          </Box>
        ),
      },
      {
        header: t("Appointment Type"),
        key: "type",
        accessor: ({ type }) => appointmentTypeOptionsMap[type || ""]?.label ?? "-",
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <TruncateTypography text={t(convertToTitleCase(status!))} />,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ id, status, consumer, requestedTimeSlots, doctor, paymentInfo, paymentStatus }) => (
          <Box display='flex' flexDirection='row' gap={2}>
            <AppointmentAccept
              id={id}
              activeHealthProgramMember={consumer?.activeHealthProgramMembers?.[0]}
              healthProgramsByActiveMember={consumer?.healthProgramsByActiveMembers?.[0]}
              requestedTimeSlots={requestedTimeSlots!}
            />

            <AppointmentReject
              id={id}
              requestedTimeSlots={requestedTimeSlots}
              buttonEl={
                <IconButton
                  sx={{
                    "& svg": {
                      width: "19px",
                      height: "19px",
                    },
                  }}
                >
                  <XIcon />
                </IconButton>
              }
            />

            <AppointmentReschedule
              status={status!}
              id={id!}
              patient={consumer}
              doctorId={doctor?.id}
              paymentInfo={paymentInfo}
              paymentStatus={paymentStatus}
              buttonEl={
                <Grid
                  sx={{
                    "& .MuiIconButton-root": {
                      width: "100%",
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      "& svg": {
                        width: "24px",
                        height: "24px",
                      },
                    }}
                  >
                    <RescheduleIcon width={"24px"} height={"24px"} />
                  </IconButton>
                </Grid>
              }
            />
          </Box>
        ),
      },
    ],
    [t]
  );
};
