import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useToggle } from "shared/hooks";
import { MarketplaceDiscountPublishStatusUpdateMutation, useMarketplaceDiscountPublishStatusUpdateMutation } from "../gql";
import { DiscountsQueryNode } from "../types";

export const ToggleDiscountsPublishStatus: FC<{ row: DiscountsQueryNode }> = ({ row }) => {
  const [checked, setChecked] = useState<boolean>(row?.isPublished);
  const { t } = useTranslation("provider");
  const { handleToggle, open } = useToggle();
  const { succeeded, failed } = useAddToast();
  const isDiscountExpired = row?.endDate && moment().isAfter(row?.endDate);
  const [productsPublishStatusUpdateMutation, { loading }] = useMarketplaceDiscountPublishStatusUpdateMutation({
    onCompleted: (data: MarketplaceDiscountPublishStatusUpdateMutation, options) => {
      const responseData = data?.[options?.variables?.isPublish ? "publishMarketplaceDiscount" : "unPublishMarketplaceDiscount"];
      if (responseData?.id) {
        setChecked(responseData?.isPublished as boolean);
        succeeded(!checked ? t("Discount published successfully!") : t("Discount is now unpublished"));
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedError = formatGraphQLError(graphQLErrors);
      const errorMessage = formattedError?.includes("Discount overlaps with")
        ? t("This product/package is already on sale in this period")
        : formattedError;
      failed(t(errorMessage));
    },
  });
  const handlePublishPackage = () => {
    productsPublishStatusUpdateMutation({
      variables: {
        id: row?.id,
        isPublish: !checked,
      },
    });
  };

  useEffect(() => {
    setChecked(row?.isPublished);
  }, [row?.isPublished]);
  return (
    <>
      <CustomToggleButton disabled={loading || isDiscountExpired} checked={checked} onChange={handleToggle} isLoading={loading} />
      {open && (
        <CustomDialog
          type='warning'
          isOpen={open}
          text={{
            body: checked ? t("Are you sure you want to un publish this discount?") : t("Are you sure you want to publish this discount?"),
          }}
          onConfirm={handlePublishPackage}
          onClose={handleToggle}
        />
      )}
    </>
  );
};
