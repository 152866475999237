import { sourceOptionsMap } from "@health/enum-options";
import { PatientHealthParameter, VisitVitalSigns, VisitVitalSignsChangePercentage } from "@health/queries/types";
import { keyBy, last } from "lodash";
import moment from "moment";
import { IVitalSignChartData } from "shared/modules/patient/components/VitalSigns/types";

export const vitalSignsCode = {
  temperature: "PA-VS-001",
  heartRate: "PA-VS-002",
  respiratoryRate: "PA-VS-003",
  bloodPressureHigh: "PA-VS-004-Sys",
  bloodPressureLow: "PA-VS-004-Dia",
  oxygenSaturation: "PA-VS-005",
};

export const physicalExaminationCode = {
  height: "PA-PE-001",
  weight: "PA-PE-002",
  bmi: "PA-PE-003",
  ecg: "PA-PE-016",
};

export const createPatientHealthParameterItem = (
  patientHealthParameters: PatientHealthParameter[],
  unit: string
): IVitalSignChartData[] => {
  const sortedItems = patientHealthParameters?.sort((a, b) => moment(a?.createdDate).diff(moment(b?.createdDate)));

  return sortedItems?.map((item, index) => ({
    ...item,
    value: item?.valueNumber!,
    unit: unit,
    date: moment(item?.createdDate).format("DD MMM YYYY HH:mm"),
    source: sourceOptionsMap[item?.source!]?.label!,
    currentData: index + 1 === patientHealthParameters?.length || false,
  }));
};

export const createVitalSignItem = (
  visitVitalSigns: (VisitVitalSigns & { date: string })[],
  hpCode: string,
  unit: string
): IVitalSignChartData[] => {
  const items = visitVitalSigns?.filter(item => item?.hpCode === hpCode) || [];
  const sortedItems = items?.sort((a, b) => moment(a?.date).diff(moment(b?.date)));

  return sortedItems?.map((item, index) => ({
    ...item,
    value: item?.valueNumber!,
    unit: unit,
    source: sourceOptionsMap[item?.source!]?.label!,
    currentData: index + 1 === items?.length || false,
  }));
};

export const getVitalSignsItems = (visitVitalSigns: (VisitVitalSigns & { date: string })[]) => {
  return {
    bloodPressureLow: createVitalSignItem(visitVitalSigns, vitalSignsCode.bloodPressureLow, "mg/dL"),
    bloodPressureHigh: createVitalSignItem(visitVitalSigns, vitalSignsCode.bloodPressureHigh, "mg/dL"),
    temperature: createVitalSignItem(visitVitalSigns, vitalSignsCode.temperature, "°C"),
    oxygenSaturation: createVitalSignItem(visitVitalSigns, vitalSignsCode.oxygenSaturation, "%"),
    respiratoryRate: createVitalSignItem(visitVitalSigns, vitalSignsCode.respiratoryRate, "°bpm"),
    heartRate: createVitalSignItem(visitVitalSigns, vitalSignsCode.heartRate, "°bpm"),
  };
};

export const getVitalSignsItemsLastItem = (visitVitalSigns: (VisitVitalSigns & { date: string })[]) => {
  const items = getVitalSignsItems(visitVitalSigns);

  return {
    bloodPressureLow: last(items.bloodPressureLow),
    bloodPressureHigh: last(items.bloodPressureHigh),
    temperature: last(items.temperature),
    oxygenSaturation: last(items.oxygenSaturation),
    respiratoryRate: last(items.respiratoryRate),
    heartRate: last(items.heartRate),
  };
};

export const getVitalSignsChangePercentage = (vitalSignsChangePercentage: Array<VisitVitalSignsChangePercentage | null>) => {
  const visitVitalSignsChangePercentageMap = keyBy(vitalSignsChangePercentage, "hpCode");

  return {
    bloodPressureLow: visitVitalSignsChangePercentageMap[vitalSignsCode.bloodPressureLow]?.changePercentage,
    bloodPressureHigh: visitVitalSignsChangePercentageMap[vitalSignsCode.bloodPressureHigh]?.changePercentage,
    temperature: visitVitalSignsChangePercentageMap[vitalSignsCode.temperature]?.changePercentage,
    oxygenSaturation: visitVitalSignsChangePercentageMap[vitalSignsCode.oxygenSaturation]?.changePercentage,
    respiratoryRate: visitVitalSignsChangePercentageMap[vitalSignsCode.respiratoryRate]?.changePercentage,
    heartRate: visitVitalSignsChangePercentageMap[vitalSignsCode.heartRate]?.changePercentage,
  };
};
