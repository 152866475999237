/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetOrderToDeliveredMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SetOrderToDeliveredMutation = { __typename?: 'Mutation', setOrderToDelivered?: { __typename?: 'SetOrderToDelivered', orderErrors: Array<{ __typename?: 'OrderError', code: Types.OrderErrorCode, field?: string | null, message?: string | null }>, order?: { __typename?: 'Order', id: string, status: Types.OrderStatus } | null } | null };


export const SetOrderToDeliveredDocument = gql`
    mutation SetOrderToDelivered($id: ID!) {
  setOrderToDelivered(id: $id) {
    orderErrors {
      code
      field
      message
    }
    order {
      id
      status
    }
  }
}
    `;
export type SetOrderToDeliveredMutationFn = Apollo.MutationFunction<SetOrderToDeliveredMutation, SetOrderToDeliveredMutationVariables>;

/**
 * __useSetOrderToDeliveredMutation__
 *
 * To run a mutation, you first call `useSetOrderToDeliveredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrderToDeliveredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrderToDeliveredMutation, { data, loading, error }] = useSetOrderToDeliveredMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetOrderToDeliveredMutation(baseOptions?: Apollo.MutationHookOptions<SetOrderToDeliveredMutation, SetOrderToDeliveredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrderToDeliveredMutation, SetOrderToDeliveredMutationVariables>(SetOrderToDeliveredDocument, options);
      }
export type SetOrderToDeliveredMutationHookResult = ReturnType<typeof useSetOrderToDeliveredMutation>;
export type SetOrderToDeliveredMutationResult = Apollo.MutationResult<SetOrderToDeliveredMutation>;
export type SetOrderToDeliveredMutationOptions = Apollo.BaseMutationOptions<SetOrderToDeliveredMutation, SetOrderToDeliveredMutationVariables>;