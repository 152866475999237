/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitVitalSignsChangePercentageQueryVariables = Types.Exact<{
  patientId: Types.Scalars['ID'];
}>;


export type VisitVitalSignsChangePercentageQuery = { __typename?: 'Query', visitVitalSignsChangePercentage?: Array<{ __typename?: 'VisitVitalSignsChangePercentage', hpCode?: string | null, changePercentage?: any | null } | null> | null };


export const VisitVitalSignsChangePercentageDocument = gql`
    query VisitVitalSignsChangePercentage($patientId: ID!) {
  visitVitalSignsChangePercentage(patientId: $patientId) {
    hpCode
    changePercentage
  }
}
    `;

/**
 * __useVisitVitalSignsChangePercentageQuery__
 *
 * To run a query within a React component, call `useVisitVitalSignsChangePercentageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitVitalSignsChangePercentageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitVitalSignsChangePercentageQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useVisitVitalSignsChangePercentageQuery(baseOptions: Apollo.QueryHookOptions<VisitVitalSignsChangePercentageQuery, VisitVitalSignsChangePercentageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitVitalSignsChangePercentageQuery, VisitVitalSignsChangePercentageQueryVariables>(VisitVitalSignsChangePercentageDocument, options);
      }
export function useVisitVitalSignsChangePercentageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitVitalSignsChangePercentageQuery, VisitVitalSignsChangePercentageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitVitalSignsChangePercentageQuery, VisitVitalSignsChangePercentageQueryVariables>(VisitVitalSignsChangePercentageDocument, options);
        }
export type VisitVitalSignsChangePercentageQueryHookResult = ReturnType<typeof useVisitVitalSignsChangePercentageQuery>;
export type VisitVitalSignsChangePercentageLazyQueryHookResult = ReturnType<typeof useVisitVitalSignsChangePercentageLazyQuery>;
export type VisitVitalSignsChangePercentageQueryResult = Apollo.QueryResult<VisitVitalSignsChangePercentageQuery, VisitVitalSignsChangePercentageQueryVariables>;