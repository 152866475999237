import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, Typography, useTheme } from "@toolkit/ui";
import { GuidedCareTaskList } from "pages/GuidedCareTasks/components/GuidedCareTaskList/GuidedCareTaskList";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ComingSoonAppointmentsGrid, PendingAppointmentsGrid } from "..";
import { dashboardPaths } from "../../constants";
import { DoctorsList } from "../DoctorsList";
import { EventCards } from "./Cards";

export const AdminDoctorDashboardView: FC = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const theme = useTheme();

  const handleOpenAllDoctorsList = () => {
    navigate(dashboardPaths.allDoctorsList.fullPath);
  };

  return (
    <Grid container padding={4}>
      <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
        <Box display='flex' justifyContent='space-between'>
          <Typography
            variant='subtitle1'
            component='span'
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            mb={1}
          >
            {t("Doctors-list")}
          </Typography>

          <Typography
            variant='subtitle1'
            component='span'
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.regular}
            mb={1}
            sx={{
              cursor: "pointer",
            }}
            onClick={handleOpenAllDoctorsList}
          >
            {t("See All")}
          </Typography>
        </Box>

        <DoctorsList />
      </Grid>

      <Grid item xs={12} md={8} container spacing={1} px={2} order={{ xs: 1, md: 2 }}>
        <Grid item xs={12} marginTop={4.5}>
          <EventCards />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant='subtitle1'
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            mb={1}
          >
            {t("Upcoming Appointments")}
          </Typography>

          <ComingSoonAppointmentsGrid />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant='subtitle1'
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            mb={1}
          >
            {t("Pending Items")}
          </Typography>

          <PendingAppointmentsGrid />
        </Grid>

        <Grid item xs={12}>
          <GuidedCareTaskList />
        </Grid>
      </Grid>
    </Grid>
  );
};
