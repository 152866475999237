import { MarketplaceDiscountSortingField, MarketplaceProductSortingField, SortDirection } from "@health/queries/types";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTableColumnProps, format, TruncateTooltipTypography, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { ToggleDiscountsPublishStatus } from "../../components";
import { DiscountsQueryNode } from "../../types";
import { getYesNoAutocompleteFilter, marketplaceDiscountTypeOptionsMap } from "@health/enum-options";
import { getHealthPackagesAutocompleteFilter, getMarketplaceProductsAutocompleteFilter } from "@health/autocompletes";

export const useDiscountsListTableColumns = (): CustomTableColumnProps<DiscountsQueryNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => <TruncateTooltipTypography text={name || "-"} />,
        filter: {
          type: "string",
          name: "name",
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplaceProductSortingField.Name,
      },
      {
        key: "type",
        header: t("Discount Type"),
        accessor: ({ type }) => marketplaceDiscountTypeOptionsMap[type]?.label || "-",
      },
      {
        key: "Products",
        header: t("Products"),
        accessor: ({ products }) => (
          <CustomDialog type='info' title={"Discount Products"}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {products?.length
                ? products?.map(product => pickLocalizedValue(product?.name!, product?.nameAr!)).join("\n")
                : t("No product provided")}
            </Typography>
          </CustomDialog>
        ),
        filter: getMarketplaceProductsAutocompleteFilter({ name: "productId" }),
      },
      {
        key: "health-packages",
        header: t("Health Packages"),
        accessor: ({ healthPackages }) => (
          <CustomDialog type='info' title={"Discount Health Packages"}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {healthPackages?.length
                ? healthPackages?.map(healthPackage => pickLocalizedValue(healthPackage?.name!, healthPackage?.nameAr!)).join("\n")
                : t("No health Package provided")}
            </Typography>
          </CustomDialog>
        ),
        filter: getHealthPackagesAutocompleteFilter({ name: "healthPackageIds" }),
      },
      {
        key: "discount",
        header: t("Discount"),
        accessor: ({ amount }) => amount || "-",
      },
      {
        key: "startDate",
        header: t("Start Date"),
        accessor: ({ startDate }) => format(startDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language),
        filter: { type: "date", name: "startDate" },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplaceDiscountSortingField.StartDate,
      },
      {
        key: "endDate",
        header: t("End Date"),
        accessor: ({ endDate }) => format(endDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language),
        filter: {
          type: "date",
          name: "endDate",
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplaceDiscountSortingField.EndDate,
      },

      {
        key: "isPublished",
        header: t("Published"),
        accessor: row => <ToggleDiscountsPublishStatus row={row} />,
        filter: {
          ...getYesNoAutocompleteFilter({ name: "isPublished" }),
          label: t("Is Published"),
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: "isPublished",
      },
    ];
  }, []);
};
