import { OrderTypesEnum } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { IOrderContainer } from "../actions/types";
import { OrderSection } from "./OrderSection";
import { OrdersFromCheckout } from "./OrdersFromCheckout";
import { MedicalAlerts } from "./eRxSections/MedicalAlerts";
import { useERXSections } from "./eRxSections/useeRxSections";

export const OrdersContainer: FC<IOrderContainer> = ({ data, hasAction }) => {
  const { color, number, type, products, timeOutComponent, medicalAlerts, iseRxOrder = true, status } = data;
  const { t } = useTranslation("provider");
  const { eRxSections, address } = useERXSections(data);

  return (
    <>
      {iseRxOrder && Boolean(medicalAlerts?.length) && <MedicalAlerts medicalAlerts={medicalAlerts} />}

      {!iseRxOrder && <OrderSection status={status} color={color} title={t("Checkout Order")} />}
      {iseRxOrder && type && (
        <OrderSection
          status={status}
          color={color}
          title={type === OrderTypesEnum.PickupFromPharmacy ? t("Pickup Order") : t("Delivery Order")}
        />
      )}

      {iseRxOrder ? (
        <>
          {eRxSections
            .filter(item => item.hasData)
            .map((item, index) => (
              <OrderSection {...item} key={index} color={item.color} hasBG={index % 2 == 0} hasAction={hasAction} status={status} />
            ))}
        </>
      ) : (
        <OrdersFromCheckout city={address} color={color} number={number} products={products} timeOutComponent={timeOutComponent!} />
      )}
    </>
  );
};
