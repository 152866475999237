import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { AgentInformationForm } from "../AgentFromInformation";
import { AgentUpsertFormSchema, agentUpsertFormSchemaDefaultValues } from "./AgentUpsertFormSchema";
import { convertAgentToFormValues } from "../../utils";
import { ConditionsAndRulesFormInformation } from "../ConditionsAndRulesFormInformation";
import { KnowledgeBaseForm } from "../KnowledgeBaseForm";
export const AgentUpsertForm = props => {
    const { agent, isLoading, isSubmitting, submitButtonLabel, isAdmin, onChange, handleCancel } = props;
    const form = useCustomForm({
        schema: AgentUpsertFormSchema,
        defaultValues: agentUpsertFormSchemaDefaultValues,
        mode: "onChange",
    });
    const { handleSubmit, setValues } = form;
    const { t } = useTranslation("domains");
    const isButtonDisabled = isLoading || isSubmitting;
    const onSubmit = (values) => {
        onChange(values);
    };
    useEffect(() => {
        if (agent) {
            const _brand = convertAgentToFormValues(agent);
            setValues(_brand);
        }
    }, [agent, setValues]);
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsx(PageWrapper, Object.assign({ actions: _jsx(FormActions, { hasCancel: true, hasFormButton: true, isLoading: isLoading, formButtonTitle: submitButtonLabel, onNavigation: handleCancel, newButtonDisabled: isButtonDisabled }) }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: formGirdSpacing }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormCard, Object.assign({ title: t("General Information"), loading: isLoading, doYouHaveData: true }, { children: _jsx(AgentInformationForm, { isAdmin: isAdmin }) })) })), _jsx(KnowledgeBaseForm, {}), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormCard, Object.assign({ title: t("Conditions & Rules"), loading: isLoading, doYouHaveData: true }, { children: _jsx(ConditionsAndRulesFormInformation, {}) })) }))] })) })) })) })));
};
