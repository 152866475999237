import { MedicationOrderLineInput } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { MedicationsMappingDialog } from "../cards/MedicationsMappingDialog";
import { OrderMedicationsMappingProps } from "../cards/types";
import { useVendorOrderAcceptMutation } from "../gql";

export interface MedicationsProps extends OrderMedicationsMappingProps {
  orderId: string;
}

export const ViewAndAcceptButton: FC<MedicationsProps> = ({ products, medications, referenceNumber, patientIdentifier, orderId }) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const [orderVendorAccept, { loading }] = useVendorOrderAcceptMutation({
    onCompleted: data => {
      if (data?.vendorOrderAccept?.orderErrors?.length === 0) {
        succeeded(t("Order accepted successfully"));
      } else {
        failed(t(formatMessageErrors(data?.vendorOrderAccept?.orderErrors)));
      }
    },
    onError: ({ networkError }) => {
      failed(formatGraphQLError(networkError?.["result"]?.Errors));
    },
  });

  const handleOrderAccepted = (acceptedMedications: MedicationOrderLineInput[]): void => {
    orderVendorAccept({
      variables: {
        id: orderId,
        input: {
          acceptedMedications,
        },
      },
    });
  };

  return (
    <MedicationsMappingDialog
      isLoading={loading}
      products={products}
      medications={medications}
      patientIdentifier={patientIdentifier}
      referenceNumber={referenceNumber}
      onSubmit={handleOrderAccepted}
      buttonLabel={t("View & Accept")}
    />
  );
};
