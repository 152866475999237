import { OrderStatus } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { MedicationsMappingDialog } from "../cards/MedicationsMappingDialog";
import { OrderMedicationsMappingProps } from "../cards/types";
import { useResubmitOrderGetErxHubApprovalMutation } from "../gql";

type ResubmitProps = OrderMedicationsMappingProps & {
  orderId: string;
  status: OrderStatus;
};

export const ResubmitButton: FC<ResubmitProps> = ({ orderId, referenceNumber, patientIdentifier, medications, status }) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const [resubmitOrderGetErxHubApprovalMutation, { loading: isGetErxApprovalLoading }] = useResubmitOrderGetErxHubApprovalMutation({
    onCompleted: response => {
      const errors = response.resubmitOrderGetErxHubApproval?.orderErrors;
      if (errors?.length) {
        const formattedErrors = formatMessageErrors(errors);
        failed(formattedErrors);
      } else {
        succeeded(t("Your order has been resubmitted successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedErrors = formatGraphQLError(graphQLErrors);
      failed(formattedErrors);
      console.error("Resubmit", formattedErrors);
    },
  });

  const handleSubmit = value => {
    resubmitOrderGetErxHubApprovalMutation({
      variables: {
        id: orderId,
        input: {
          acceptedMedications: value,
        },
      },
    });
  };
  const getTitleFromStatus = (): string => {
    if (status === OrderStatus.ErxHubAuthSubmitTimedOut) {
      return t("Get IO health Approval");
    }
    return t("Resubmit");
  };
  return (
    <MedicationsMappingDialog
      isLoading={isGetErxApprovalLoading}
      buttonLabel={getTitleFromStatus()}
      medications={medications?.filter(el => Boolean(el?.id))}
      patientIdentifier={patientIdentifier}
      referenceNumber={referenceNumber}
      onSubmit={handleSubmit}
    />
  );
};
