import React, { FC } from "react";
import { Box, type BoxProps } from "../../base/mui";
import { JoinCallIcon } from "../../icons";

export const PulseConnection: FC<BoxProps> = props => {
  return (
    <Box
      {...props}
      sx={{
        margin: "auto",
        marginBlock: "10px",
        height: "120px",
        width: "120px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgb(95 160 46)",
        borderRadius: "50%",
        boxShadow: "0 0 0 0 rgba(95, 160, 46, 1)",
        transform: "scale(1)",
        animation: "pulse-green 2s infinite",
        "@keyframes pulse-green": {
          "0%": {
            transform: "scale(0.90)",
            boxShadow: "0 0 0 0 rgba(95, 160, 46, 0.7)",
          },
          "70%": {
            transform: "scale(1)",
            boxShadow: "0 0 0 10px rgba(95, 160, 46, 0)",
          },
          "100%": {
            transform: "scale(0.90)",
            boxShadow: "0 0 0 0 rgba(95, 160, 46, 0)",
          },
        },
        ...props.sx,
      }}
    >
      <JoinCallIcon
        sx={{
          height: "50px",
          width: "50px",
        }}
      />
    </Box>
  );
};
