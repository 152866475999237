// /* eslint-disable no-restricted-imports */
/* eslint-disable no-nested-ternary */
import { NetworkStatus } from "@apollo/client/core";
import { useOnAppointmentStatusUpdate } from "@health/common";
import { AppointmentSortingField, H_OrderDirection } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment/moment";
import { useFetchDashboardAppointmentsQuery } from "pages/dashboard/gql/queries";
import { FC } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isReceptionistUser } from "utils";
import { CardsContainer, EmptyCard, EventCard } from "../Cards";
import { ViewPatientJourney } from "../ViewPatientJourney/ViewPatientJourney";

export const EventCards: FC = () => {
  const {
    data,
    networkStatus,
    refetch: refetchAppointment,
  } = useFetchDashboardAppointmentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      isAdmin: true,
      first: 3,
      filter: {
        upcoming: true,
      },
      sortBy: {
        direction: H_OrderDirection.Asc,
        field: AppointmentSortingField.ConfirmedStartDate,
      },
    },
  });

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id) {
      refetchAppointment();
    }
  });
  const cardWidth = "510px";
  const { t } = useTranslation("provider");
  const upcomingAppointments = data?.appointments?.edges?.map(edge => edge.node) || [];
  const isLoadingAppointments = NetworkStatus.loading === networkStatus;
  const { userInfo } = useOidcUserProfile();
  const isReceptionist = isReceptionistUser(userInfo);
  const AppointmentEventCards = upcomingAppointments?.length
    ? upcomingAppointments?.map((upcomingAppointment, idx) => (
        <EventCard
          key={idx}
          isAdmin={true}
          doctorName={upcomingAppointment.doctor?.user?.fullName ?? ""}
          eventDate={moment(upcomingAppointment?.confirmedStartTime).toDate()}
          patientName={upcomingAppointment?.consumerUser?.fullName ?? ""}
          careType={upcomingAppointment?.type ?? ""}
          cardWidth={cardWidth}
        />
      ))
    : [<EmptyCard key={0} message={t("No Upcoming Appointments")} />];
  const cards = [<ViewPatientJourney key={0} />, ...AppointmentEventCards];
  isReceptionist && cards.shift();

  return <CardsContainer isLoadingData={isLoadingAppointments} cards={cards} />;
};
