import { Doctor, User } from "@health/queries/types";
import { Card, CustomInfiniteScroll, EmptyCard, Grid } from "@toolkit/ui";
import React from "react";
import DoctorCard from "./components/DoctorCard";
import DoctorProfileDrawer from "./components/DoctorProfileDrawer";
import DoctorsSearchInput from "./components/DoctorsSearchInput";
import { useDoctorsList } from "./useDoctorsList";
import DoctorCardSkeleton from "./components/DoctorCardSkeleton";

export const DoctorsList: React.VFC = () => {
  const {
    doctors,
    pageInfo,
    classes,
    isDoctorProfileOpen,
    isLoadingUserProfile,
    selectedDoctorProfile,
    fetchMoreData,
    t,
    handleFilter,
    handleDoctorSelected,
    handleCloseDoctorProfile,
  } = useDoctorsList();

  return (
    <Card>
      <DoctorsSearchInput onFilter={handleFilter} />
      {doctors?.length ? (
        <div className={classes.listContainer} id='scrollableDiv'>
          <CustomInfiniteScroll
            dataLength={Number(doctors?.length) || 0}
            onFetchMore={() => fetchMoreData()}
            hasMore={Boolean(pageInfo?.hasNextPage)}
            loader={<DoctorCardSkeleton />}
          >
            {doctors?.map(doctor => (
              <DoctorCard key={doctor.id} doctor={doctor as Doctor} onClick={handleDoctorSelected} />
            ))}
          </CustomInfiniteScroll>
        </div>
      ) : (
        <Grid container alignItems='center' justifyContent='center'>
          <EmptyCard title={t("No Data Yet!")} message='' />
        </Grid>
      )}
      <DoctorProfileDrawer
        isOpen={isDoctorProfileOpen}
        isLoadingUser={isLoadingUserProfile}
        user={selectedDoctorProfile as User}
        onClose={handleCloseDoctorProfile}
      />
    </Card>
  );
};
