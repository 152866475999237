/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetOrderToOutForDeliveryMutationVariables = Types.Exact<{
  setOrderToOutForDeliveryId: Types.Scalars['ID'];
}>;


export type SetOrderToOutForDeliveryMutation = { __typename?: 'Mutation', setOrderToOutForDelivery?: { __typename?: 'SetOrderToOutForDelivery', order?: { __typename?: 'Order', id: string } | null, orderErrors: Array<{ __typename?: 'OrderError', message?: string | null, field?: string | null, code: Types.OrderErrorCode }> } | null };


export const SetOrderToOutForDeliveryDocument = gql`
    mutation SetOrderToOutForDelivery($setOrderToOutForDeliveryId: ID!) {
  setOrderToOutForDelivery(id: $setOrderToOutForDeliveryId) {
    order {
      id
    }
    orderErrors {
      message
      field
      code
    }
  }
}
    `;
export type SetOrderToOutForDeliveryMutationFn = Apollo.MutationFunction<SetOrderToOutForDeliveryMutation, SetOrderToOutForDeliveryMutationVariables>;

/**
 * __useSetOrderToOutForDeliveryMutation__
 *
 * To run a mutation, you first call `useSetOrderToOutForDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrderToOutForDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrderToOutForDeliveryMutation, { data, loading, error }] = useSetOrderToOutForDeliveryMutation({
 *   variables: {
 *      setOrderToOutForDeliveryId: // value for 'setOrderToOutForDeliveryId'
 *   },
 * });
 */
export function useSetOrderToOutForDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<SetOrderToOutForDeliveryMutation, SetOrderToOutForDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrderToOutForDeliveryMutation, SetOrderToOutForDeliveryMutationVariables>(SetOrderToOutForDeliveryDocument, options);
      }
export type SetOrderToOutForDeliveryMutationHookResult = ReturnType<typeof useSetOrderToOutForDeliveryMutation>;
export type SetOrderToOutForDeliveryMutationResult = Apollo.MutationResult<SetOrderToOutForDeliveryMutation>;
export type SetOrderToOutForDeliveryMutationOptions = Apollo.BaseMutationOptions<SetOrderToOutForDeliveryMutation, SetOrderToOutForDeliveryMutationVariables>;