/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorOrderCancelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  cancellationReason: Types.Scalars['String'];
}>;


export type VendorOrderCancelMutation = { __typename?: 'Mutation', vendorOrderCancel?: { __typename?: 'VendorOrderCancel', order?: { __typename?: 'Order', id: string, status: Types.OrderStatus } | null, orderErrors: Array<{ __typename?: 'OrderError', field?: string | null, message?: string | null }> } | null };


export const VendorOrderCancelDocument = gql`
    mutation vendorOrderCancel($id: ID!, $cancellationReason: String!) {
  vendorOrderCancel(id: $id, cancellationReason: $cancellationReason) {
    order {
      id
      status
    }
    orderErrors {
      field
      message
    }
  }
}
    `;
export type VendorOrderCancelMutationFn = Apollo.MutationFunction<VendorOrderCancelMutation, VendorOrderCancelMutationVariables>;

/**
 * __useVendorOrderCancelMutation__
 *
 * To run a mutation, you first call `useVendorOrderCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorOrderCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorOrderCancelMutation, { data, loading, error }] = useVendorOrderCancelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cancellationReason: // value for 'cancellationReason'
 *   },
 * });
 */
export function useVendorOrderCancelMutation(baseOptions?: Apollo.MutationHookOptions<VendorOrderCancelMutation, VendorOrderCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorOrderCancelMutation, VendorOrderCancelMutationVariables>(VendorOrderCancelDocument, options);
      }
export type VendorOrderCancelMutationHookResult = ReturnType<typeof useVendorOrderCancelMutation>;
export type VendorOrderCancelMutationResult = Apollo.MutationResult<VendorOrderCancelMutation>;
export type VendorOrderCancelMutationOptions = Apollo.BaseMutationOptions<VendorOrderCancelMutation, VendorOrderCancelMutationVariables>;