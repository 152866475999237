import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type KPICardProps = {
  title: string;
  value: string | number;
  unit?: string;
  colorTitle?: string;
  description?: string;
};

export const KPICard: FC<KPICardProps> = ({ title, value, unit, colorTitle, description }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Box
      p={2}
      borderRadius={2}
      sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.mixins.shadows.xs,
      }}
    >
      <Typography
        fontSize={25}
        fontWeight={theme.mixins.fonts.fontWeight.medium}
        color={colorTitle || theme.palette.primary.main}
        whiteSpace='nowrap'
      >
        {value || 0}{" "}
        <Typography component='span' fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.regular}>
          <sup>{unit || ""}</sup>
        </Typography>
      </Typography>

      <Typography fontSize={theme.mixins.fonts.fontSize.xl} fontWeight={theme.mixins.fonts.fontWeight.medium}>
        {title}
      </Typography>

      <Typography fontSize={theme.mixins.fonts.fontSize.xs} color={theme.palette.stale.main}>
        {t("By")} {description || "-"}
      </Typography>
    </Box>
  );
};
