import { useTranslation } from "@toolkit/i18n";
import React, { FC, SyntheticEvent, useState } from "react";
import { Typography, useTheme } from "../../base/mui";

type VideoPlayerProps = {
  src: string;
  type?: string;
  width?: string | number;
  height?: string | number;
  isControlsHidden?: boolean;
  isAutoplayEnabled?: boolean;
  isMuted?: boolean;
};

export const VideoPlayer: FC<VideoPlayerProps> = props => {
  const {
    src,
    type = "video/mp4",
    width = "100%",
    height = 400,
    isControlsHidden = false,
    isAutoplayEnabled = false,
    isMuted = false,
  } = props;

  const [error, setError] = useState("");

  const { t } = useTranslation();

  const theme = useTheme();

  const handleOnError = (event: SyntheticEvent<HTMLVideoElement>) => {
    setError(t("An error occurred while loading the video, please try again"));
    console.error(event);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        key={src}
        width={width}
        height={height}
        controls={!isControlsHidden}
        autoPlay={isAutoplayEnabled}
        muted={isMuted}
        onError={handleOnError}
      >
        <source src={src} type={type} />
      </video>

      {error && <Typography color={theme.palette.error.main}> {error} </Typography>}
    </>
  );
};
