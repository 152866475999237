import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useOrderGetInsuranceApprovalMutation } from "../gql";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";

export const GetApprovalButton: FC<{ id: string; onDone?: () => void }> = ({ id, onDone }) => {
  const { t } = useTranslation("provider");
  const { classes } = useOrderActionStyles();
  const { failed, succeeded } = useAddToast();

  const [orderGetInsuranceApproval, { loading }] = useOrderGetInsuranceApprovalMutation({
    onCompleted: data => {
      if (data?.orderGetErxHubApproval?.orderErrors?.length === 0) {
        succeeded(t("Order insurance approval request sent successfully"));
        onDone?.();
      } else {
        const msg = formatMessageErrors(data?.orderGetErxHubApproval?.orderErrors);
        failed(msg);
      }
    },
    onError: () => {
      failed(t("Get Insurance Approval Failed"));
    },
  });

  const handleOrderGetInsuranceApproval = (): void => {
    orderGetInsuranceApproval({
      variables: {
        id,
      },
    });
  };

  return (
    <CircularIntegration loading={loading}>
      <Button variant='outlined' className={classes.outlinedAccept} onClick={handleOrderGetInsuranceApproval}>
        {t("Get iO Health Approval")}
      </Button>
    </CircularIntegration>
  );
};
