import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { MedicalFormClassificationUpsertForm, } from "../../../MedicalForms/forms/MedicalFormClassificationUpsert/MedicalFormClassificationUpsertForm";
import { useRef } from "react";
export const MedicalFormClassificationUpsertModal = props => {
    const { classification, isOpen, onClose, onChange } = props;
    const { t } = useTranslation("domains");
    const medicalFormClassificationUpsertFormRef = useRef(null);
    const onButtonClick = () => {
        var _a;
        (_a = medicalFormClassificationUpsertFormRef.current) === null || _a === void 0 ? void 0 : _a.submit();
    };
    return (_jsx(CustomDialog, Object.assign({ type: 'base', open: isOpen, DialogTitleProps: {
            title: classification ? t("Update Classification") : t("Add New Classification"),
            onClose,
        }, DialogActionsProps: {
            hasClose: true,
            submitTitle: classification ? t("Update") : t("Add"),
            onSubmit: onButtonClick,
        } }, { children: _jsx(MedicalFormClassificationUpsertForm, { classification: classification, ref: medicalFormClassificationUpsertFormRef, onChange: onChange }) })));
};
