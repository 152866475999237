import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, createAutocompleteOption, CustomDialog, CustomTable, Grid, Typography, useGridContext, useModal } from "@toolkit/ui";
import { DiscountCreateContainer, DiscountCreateContainerFormRef } from "pages/MarketplaceDiscounts/containers";
import { FC, useRef, useState } from "react";
import { useProductsListTableColumns } from "../containers/ProductsList/useProductListTableColumns";
import { ProductsQueryNode } from "../types";

export const DiscountModal: FC = () => {
  const { selectedRows, onRemoveSelectedRow, onResetSelectedRows } = useGridContext<ProductsQueryNode>();
  const { t } = useTranslation("provider");
  const { open, handleClose, handleOpen } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const createContainerFormRef = useRef<DiscountCreateContainerFormRef>(null);
  const columns = useProductsListTableColumns().filter(obj => ["name", "price", "image"].includes(obj.key));
  const handleDeleteRow = (index: number) => {
    onRemoveSelectedRow?.(selectedRows?.[index!]?.id!);
  };
  const handleFormSubmit = () => {
    createContainerFormRef?.current?.submit();
  };
  const handleResetProducts = () => {
    onResetSelectedRows?.();
  };
  const handleIsSubmittingValueChange = (val: boolean) => {
    setIsLoading(val);
  };
  const discountItems = {
    product: selectedRows?.map(row => createAutocompleteOption(row, "id", "name")) || [],
  };

  return (
    <Box sx={{ width: "fit-content" }} display='flex' alignItems={"center"}>
      <CustomDialog
        type='base'
        open={open}
        DialogTitleProps={{
          onClose: handleClose,
          title: t("Create Discount"),
        }}
        button={
          <Button sx={{ minWidth: "max-content" }} disabled={!selectedRows?.length} onClick={handleOpen}>
            {t("Create Discount")}
          </Button>
        }
        DialogContentProps={{
          sx: { maxHeight: "fit-content" },
        }}
        DialogActionsProps={{
          children: (
            <>
              <Button disabled={isLoading || !selectedRows?.length} onClick={handleFormSubmit}>
                {t("Make Discount")}
              </Button>
              <Button variant='outlined' onClick={handleClose}>
                {t("Cancel")}
              </Button>
            </>
          ),
        }}
      >
        <Grid container spacing={formGirdSpacing}>
          <Grid item xs={12}>
            <DiscountCreateContainer
              onSubmittingValueChange={handleIsSubmittingValueChange}
              isDiscountItemsDisplayed={false}
              ref={createContainerFormRef}
              discountItems={discountItems}
            />
          </Grid>
          <Grid item xs={12} marginX={2}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }} alignContent={"center"} marginBottom={2}>
              <Box display='flex' flexDirection='column' justifyContent='center'>
                <Typography fontSize={16}>{t("Selected Products")}</Typography>
              </Box>
              <Box>
                <Button onClick={handleResetProducts}>{t("Reset Products")}</Button>
              </Box>
            </Box>
            <CustomTable
              totalCount={selectedRows?.length!}
              data={selectedRows as ProductsQueryNode[]}
              columns={columns}
              isDeleteVisible
              onDeleteRow={(_, index) => handleDeleteRow(index!)}
              pageSize={selectedRows?.length!}
              TableContainerProps={{
                sx: {
                  height: 357.5,
                },
              }}
              emptyIconHeight={100}
              iconBoxProps={{
                marginBottom: "90px",
                height: "5px",
              }}
              pageIndex={1}
              pagesCount={1}
            />
          </Grid>
        </Grid>
      </CustomDialog>
    </Box>
  );
};
