import { useVisitsVitalSignsChanged } from "@health/common";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { VitalSignUpdateForm } from "pages/e-visit/VitalSigns/forms/VitalSignUpdate/VitalSignUpdateForm";
import { convertVisitVitalSignFormValuesToBackEndValues } from "pages/e-visit/VitalSigns/others";
import { IVitalSignUpdateFormEvent } from "pages/e-visit/VitalSigns/types";
import { FC } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useVisitsVitalSignsQuery, useVisitVitalSignsUpdateMutation } from "../../../gql";

type VitalSignUpdateContainerProps = {
  visitId: string;
  token?: string;
};

export const VitalSignUpdateContainer: FC<VitalSignUpdateContainerProps> = props => {
  const { visitId, token } = props;

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const {
    data,
    loading: isVisitsVitalSignLoading,
    refetch,
  } = useVisitsVitalSignsQuery({
    variables: {
      first: 10,
      filter: {
        visitId: visitId!,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const visitVitalSigns = data?.visitsVitalSigns?.edges?.map(item => item?.node);

  const [fetchVisitVitalSignsUpdate, { loading: isSubmitting }] = useVisitVitalSignsUpdateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.visitVitalSignsUpdate?.entityErrors!;
      if (mutationErrors?.length === 0) {
        succeeded(t("Visit Vital Signs Updated Successfully"));
        refetch();
      } else {
        failed(formatMessageErrors(mutationErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFormToken(token!),
  });

  useVisitsVitalSignsChanged(({ data: visitsVitalSignData }) => {
    if (visitsVitalSignData?.patientId) {
      refetch();
    }
  });

  const onVisitVitalSignUpdateFormChange = (event: IVitalSignUpdateFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertVisitVitalSignFormValuesToBackEndValues(event.payload.values);

      fetchVisitVitalSignsUpdate({
        variables: {
          visitId,
          input: values,
        },
      });
    }
  };

  return (
    <VitalSignUpdateForm
      token={token!}
      isLoading={isVisitsVitalSignLoading || isSubmitting}
      visitVitalSigns={visitVitalSigns!}
      onChange={onVisitVitalSignUpdateFormChange}
    />
  );
};
