import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { TimeMachineIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { isVendorAdmin } from "utils";
import { ProfessionalsFormEditPage, ProfessionalsList } from "./index";

const doctorsRoute = "/doctors";

export const doctorsPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${doctorsRoute}${this.route}`;
    },
  },
  edit: {
    route: "edit/:id",
    get fullPath() {
      return `${doctorsRoute}/${this.route}`;
    },
  },
};

export const doctorsRoutes: (props: { navigate: NavigateFunction; t: TFunction; userInfo: any }) => RouteItem = ({
  navigate,
  t,
  userInfo,
}): RouteItem => {
  const isAdminDoctorAndCanManageUsers = isVendorAdmin(userInfo) && hasPermission(PermissionEnum.ManageDoctors);
  const hidden = !isAdminDoctorAndCanManageUsers;
  return {
    id: doctorsRoute,
    text: t("Doctors", { ns: "provider" }),
    route: doctorsRoute,
    icon: <TimeMachineIcon />,
    hidden,
    subItems: [
      {
        id: doctorsPaths.indexPage.fullPath,
        text: t("Provider Professionals", { ns: "provider" }),
        route: doctorsPaths.indexPage.route,
        fullPath: doctorsPaths.indexPage.fullPath,
        icon: <TimeMachineIcon />,
        element: <ProfessionalsList />,
        onClick: (route: string) => navigate(route),
        hidden,
      },
      {
        id: doctorsPaths.edit.fullPath,
        text: t("Provider Professionals Edit", { ns: "provider" }),
        route: doctorsPaths.edit.route,
        fullPath: doctorsPaths.edit.fullPath,
        icon: <TimeMachineIcon />,
        element: <ProfessionalsFormEditPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
