import { IEnumToOptionsMap } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { JourneyActivityFilter } from "shared/modules/patient/types";

export const journeyActivityFilterOptionsMap: IEnumToOptionsMap<JourneyActivityFilter> = {
  [JourneyActivityFilter.ShowAll]: {
    key: JourneyActivityFilter.ShowAll,
    label: i18n.t("Show All Activities", { ns: "provider" }),
    value: JourneyActivityFilter.ShowAll,
  },
  [JourneyActivityFilter.HideMedicalMessages]: {
    key: JourneyActivityFilter.HideMedicalMessages,
    label: i18n.t("Hide Journey Msgs", { ns: "provider" }),
    value: JourneyActivityFilter.HideMedicalMessages,
  },
};

export const journeyActivityFilterOptions = Object.values(journeyActivityFilterOptionsMap);
