import React, { useEffect, useState } from "react";
import { CartContext } from "./context";
import { getCartId, removeCartId, saveCartId } from "./utils";
export const CartContextProvider = (props) => {
    const handleDeleteCart = () => {
        removeCartId();
        setState(Object.assign(Object.assign({}, state), { cartId: "" }));
    };
    const handleSaveCart = (cartId = "") => {
        saveCartId(cartId);
        setState(Object.assign(Object.assign({}, state), { cartId: cartId }));
    };
    const initState = {
        cartId: getCartId(),
        onDeleteCart: handleDeleteCart,
        onSaveCart: handleSaveCart,
    };
    const [state, setState] = useState(initState);
    useEffect(() => {
        setState(initState);
    }, []);
    useEffect(() => {
        setState(Object.assign(Object.assign({}, state), { cartId: getCartId() }));
    }, [getCartId()]);
    return React.createElement(CartContext.Provider, { value: state }, props.children);
};
