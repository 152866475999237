import { TPeer } from "@meetora/schema/Peer";
import { useModal } from "@toolkit/ui";
import { PropsWithChildren, useEffect } from "react";
import { createContext, FC, useContext, useState } from "react";

export interface DashboardContextValue {
  selectedPeer?: TPeer;
  open: boolean;
  onModalClose: () => void;
  handleSelectPeer: (peer?: TPeer) => void;
}

export const DashboardContext = createContext<DashboardContextValue>({} as DashboardContextValue);
export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedPeer, setSelectedPeer] = useState<TPeer | undefined>(undefined);
  const { open, handleOpen, handleClose } = useModal();
  useEffect(() => {
    selectedPeer && handleOpen();
  }, [selectedPeer]);

  const onModalClose = () => {
    setSelectedPeer(undefined);
    handleClose();
  };

  const handleSelectPeer = (peer?: TPeer) => {
    setSelectedPeer(peer);
  };

  return (
    <DashboardContext.Provider
      value={{
        selectedPeer,
        onModalClose,
        open,
        handleSelectPeer,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export {};
