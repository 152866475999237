import { FC, useCallback } from "react";
import { CircularProgress } from "@toolkit/ui";
import { useVisitCallStyles } from "./VisitCall.styles";
import { useVisitCallHook } from "./useVisitCallHook";
import { VisitCallEmbed } from "./VisitCallEmbed";
import { VisitCallError } from "./VisitCallError";
import { useNavigate } from "react-router-dom";

type VisitCallProps = {
  meetingLink: string;
};

export const VisitCall: FC<VisitCallProps> = ({ meetingLink }) => {
  const { classes } = useVisitCallStyles();
  const { meetingInfo, error, handleRetry } = useVisitCallHook({ meetingLink });
  const navigate = useNavigate();

  const onRequestClose = useCallback(
    (closeWindow = false) => {
      if (closeWindow) {
        window.close();
      }
      navigate("/");
    },
    [navigate]
  );

  if (!meetingInfo) {
    return (
      <div className={classes.content}>
        {!error ? (
          <CircularProgress color='primary' size={50} />
        ) : (
          <VisitCallError className={classes.error} onClick={handleRetry}></VisitCallError>
        )}
      </div>
    );
  }

  return <VisitCallEmbed src={meetingInfo.link} token={meetingInfo.token} onRequestClose={onRequestClose} />;
};
