import { useReactiveVar } from "@apollo/client";
import {
  EVENT_TOPIC,
  OrderDeletedNotificationEvent,
  OrderStatusChangedNotificationEvent,
  TOPIC_LISTENER,
  useSubscribeToNotificationTopic,
} from "@health/common";
import { OrderDeliveryStatusEnum, OrderStatus } from "@health/queries/types";
import { handleDeleteEntityFromCache } from "@toolkit/apollo";
import { useMediaQuery } from "@toolkit/ui";
import { FC, PropsWithChildren, useCallback, useEffect } from "react";
import { branchUserPreferenceVar } from "shared/components";
import { client } from "shared/configs/apollo";
import { useOrderTimeoutsQuery, useOrdersVendorLazyQuery } from "../gql";
import { ORDERS_STATUS, statuses } from "../statuses";
import { shouldRefetchDelivered, shouldRefetchDispensing, shouldRefetchNew, shouldRefetchPending } from "../utils";
import { ActionsEnum } from "./actions";
import { OrderPageContext } from "./context";
import { handleGetOrderDataResponse } from "./helpers";
import { useOrderPageReducer } from "./reducer";
import { KeyOfStatuses } from "./types";
import { toConstantCase } from "./utils";

export const OrderPageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useOrderPageReducer();
  const pharmacyBranch = useReactiveVar(branchUserPreferenceVar);
  const isMatch = useMediaQuery("(max-width:1200px)");
  const [getOrderData, { loading }] = useOrdersVendorLazyQuery();
  const { data } = useOrderTimeoutsQuery();
  const time = data?.siteSettings;

  const onOrderDeletedListener: TOPIC_LISTENER<OrderDeletedNotificationEvent>[EVENT_TOPIC.ORDER_DELETED] = ({ data }) => {
    fetchDataByStatus(ORDERS_STATUS.NEW, state.filter, pharmacyBranch?.branch?.id);
    handleDeleteEntityFromCache("Order", data.id, client);
  };

  const onOrderDeleted: TOPIC_LISTENER<OrderDeletedNotificationEvent>[EVENT_TOPIC.ORDER_DELETED] = useCallback(onOrderDeletedListener, [
    state.filter,
    pharmacyBranch?.branch?.id,
  ]);
  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_DELETED, onOrderDeleted);

  const onOrderStatusChangedListener: TOPIC_LISTENER<OrderStatusChangedNotificationEvent>[EVENT_TOPIC.ORDER_STATUS_UPDATE] = ({ data }) => {
    const orderStatus = toConstantCase(data?.status) as OrderStatus;
    const deliveryStatus = toConstantCase(data?.deliveryStatus) as OrderDeliveryStatusEnum;

    shouldRefetchNew(orderStatus) && fetchDataByStatus(ORDERS_STATUS.NEW, state.filter, pharmacyBranch?.branch?.id);
    shouldRefetchPending(orderStatus) && fetchDataByStatus(ORDERS_STATUS.PENDING, state.filter, pharmacyBranch?.branch?.id);
    shouldRefetchDispensing(orderStatus) && fetchDataByStatus(ORDERS_STATUS.DISPENSING, state.filter, pharmacyBranch?.branch?.id);
    shouldRefetchDelivered(deliveryStatus, orderStatus) &&
      fetchDataByStatus(ORDERS_STATUS.DELIVERING, state.filter, pharmacyBranch?.branch?.id);
  };

  const onOrderStatusChanged: TOPIC_LISTENER<OrderStatusChangedNotificationEvent>[EVENT_TOPIC.ORDER_STATUS_UPDATE] = useCallback(
    onOrderStatusChangedListener,
    [state.filter, pharmacyBranch?.branch?.id]
  );
  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_STATUS_UPDATE, onOrderStatusChanged);

  const handleChangeView = () => {
    dispatch({ type: ActionsEnum.ChangeView });
  };

  useEffect(() => {
    isMatch && state.selectedView === "Queue" && handleChangeView();
  }, [isMatch]);
  const handleToggleDeliveringColumnVisibility = () => {
    dispatch({ type: ActionsEnum.ToggleDeliveringColumnVisible });
  };
  const fetchDataByStatus = (status: string, number?, branches?) => {
    const selectedStatuses = statuses?.[status];
    getOrderData({
      variables: {
        first: 3,
        filter: {
          status: selectedStatuses,
          numbers: number ? [number] : [],
          branches: branches ? [branches] : [],
        },
      },
    }).then(handleGetOrderDataResponse(dispatch, status, number, branches, loading));
  };

  const handleChangeSelectedTab = (status: KeyOfStatuses) => {
    dispatch({ type: ActionsEnum.ChangeSelectedTab, value: status });
    fetchDataByStatus(status, state.filter); // Todo Remove it
  };
  const fetchDataBySelectedTab = () => {
    fetchDataByStatus(state.selectedTab, state.filter);
  };

  const handleFilter = value => {
    dispatch({ type: ActionsEnum.FilterData, value: String(value?.numbers) });
  };

  return (
    <OrderPageContext.Provider
      value={{
        ...state,
        time,
        onFilter: handleFilter,
        onChangeView: handleChangeView,
        onToggleDeliveringColumnVisibility: handleToggleDeliveringColumnVisibility,
        onChangeSelectedTab: handleChangeSelectedTab,
        fetchDataBySelectedTab,
        fetchDataByStatus,
      }}
    >
      {children}
    </OrderPageContext.Provider>
  );
};
