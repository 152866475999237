import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useToggle } from "shared/hooks";
import { MarketplacePromotionPublishStatusUpdateMutation, useMarketplacePromotionPublishStatusUpdateMutation } from "../gql";
import { PromotionsQueryNode } from "../types";

export const TogglePromotionsPublishStatus: FC<{ row: PromotionsQueryNode }> = ({ row }) => {
  const [checked, setChecked] = useState<boolean>(row?.isPublished);
  const { t } = useTranslation("provider");
  const { handleToggle, open } = useToggle();
  const { succeeded, failed } = useAddToast();
  const isExpired = row?.endDate && moment().isAfter(row?.endDate);
  const [productsPublishStatusUpdateMutation, { loading }] = useMarketplacePromotionPublishStatusUpdateMutation({
    onCompleted: (data: MarketplacePromotionPublishStatusUpdateMutation, options) => {
      const responseData = data?.[options?.variables?.isPublish ? "publishMarketplacePromotion" : "unPublishMarketplacePromotion"];
      if (responseData?.id) {
        setChecked(responseData?.isPublished as boolean);
        succeeded(!checked ? t("Promotion published successfully!") : t("Promotion is now unpublished"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handlePublishPackage = () => {
    productsPublishStatusUpdateMutation({
      variables: {
        id: row?.id,
        isPublish: !checked,
      },
    });
  };

  useEffect(() => {
    setChecked(row?.isPublished);
  }, [row?.isPublished]);

  return (
    <>
      <CustomToggleButton disabled={loading || isExpired} checked={checked} onChange={handleToggle} isLoading={loading} />
      {open && (
        <CustomDialog
          type='warning'
          isOpen={open}
          text={{
            body: checked
              ? t("Are you sure you want to un publish this promotion?")
              : t("Are you sure you want to publish this promotion?"),
          }}
          onConfirm={handlePublishPackage}
          onClose={handleToggle}
        />
      )}
    </>
  );
};
