import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode, Lab } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { createAutocompleteOption, Grid, MenuItem, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { labs } from "../CoPilot/components/LabTestResultsForm/constants";
import { useSearchParams } from "react-router-dom";
import { getApolloContextFormToken } from "shared/utils";
import { getRequiredValidation } from "@toolkit/core";

export const LabTestsForm: FC<{ data: Lab }> = ({ data }) => {
  const { t } = useTranslation("provider");

  const [params] = useSearchParams();
  const token = params.get("token");

  const { register, setValue } = useFormContext();

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("code", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  useEffect(() => {
    setValue("code", data?.code ? createAutocompleteOption(data, "code", item => item?.code + " - " + item?.display) : undefined);
  }, [data, data?.code, setValue]);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <SystemCodeAutocomplete
          name={"code"}
          disabled={Boolean(data?.code)}
          context={getApolloContextFormToken(token!)}
          filter={{ codeSystemCode: CodeSystemCode.LabTest }}
        />
      </Grid>

      {data && (
        <>
          <Grid item>
            <TextField fullWidth type='number' placeholder={t("a1c")} label={t("a1c")} {...register("a1c")} />
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("acr")} label={t("acr")} {...register("acr")} />
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("abi")} label={t("abi")} {...register("abi")} />
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("pbh")} label={t("pbh")} {...register("pbh")} />
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("bmd")} label={t("bmd")} {...register("bmd")} />
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("pbl")} label={t("pbl")} {...register("pbl")} />
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("bmi")} label={t("bmi")} {...register("bmi")} />
          </Grid>

          <Grid item>
            <TextField fullWidth placeholder={t("Retinopathy")} label={t("Retinopathy")} {...register("retinopathy")} select>
              {labs
                .find(l => l.code === "retinopathy")
                ?.options?.map(option => (
                  <MenuItem key={option!} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("egfr")} label={t("egfr")} {...register("egfr")} />
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("ldl")} label={t("ldl")} {...register("ldl")} />
          </Grid>

          <Grid item>
            <TextField fullWidth type='number' placeholder={t("cvd")} label={t("cvd")} {...register("cvd")} select>
              {labs
                .find(l => l.code === "cvd")
                ?.options?.map((option, index) => (
                  <MenuItem key={option!} value={index}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              type='number'
              placeholder={t("Frequency of visit")}
              label={t("Frequency of visit")}
              {...register("frequency_of_visit")}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
