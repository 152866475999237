import { detectFileType, FileType } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, EmptyDataIcon, Typography, useTheme, VideoPlayer, WaveSurferAudio } from "@toolkit/ui";
import React, { FC } from "react";
import { useHealthParameterStethoscopeAndOtoscopeTypeStyle } from "./useHealthParameterStethoscopeAndOtoscopeTypeStyle";

type HealthParameterStethoscopeAndOtoscopeTypeProps = {
  url: string;
  isAutoplayEnabled?: boolean;
};

export const HealthParameterStethoscopeAndOtoscopeType: FC<HealthParameterStethoscopeAndOtoscopeTypeProps> = props => {
  const { url, isAutoplayEnabled = false } = props;

  const { t } = useTranslation("provider");
  const theme = useTheme();

  const { classes } = useHealthParameterStethoscopeAndOtoscopeTypeStyle();

  if (url) {
    const fileType = detectFileType(url);

    if (fileType === FileType.AUDIO) {
      return <WaveSurferAudio url={url} isAutoplayEnabled={isAutoplayEnabled} />;
    } else if (fileType === FileType.VIDEO) {
      return <VideoPlayer src={url} isAutoplayEnabled={isAutoplayEnabled} height={350} />;
    } else if (fileType === FileType.IMAGE) {
      return <img key={url} src={url} alt={t("Otoscope Image")} className={classes.image} />;
    } else {
      return <> {t("Unknown File Type")} </>;
    }
  } else {
    return (
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <EmptyDataIcon height={90} />
        <Typography fontWeight={theme.mixins.fonts.fontWeight.medium}>{t("No data available")}</Typography>
      </Box>
    );
  }
};
