import { CustomDialog, Divider } from "@toolkit/ui";
import { FC, MouseEvent, useState } from "react";
import { HealthParameterStethoscopeAndOtoscopeType } from "../../components/HealthParameterStethoscopeAndOtoscopeType/HealthParameterStethoscopeAndOtoscopeType";
import { PatientHealthParameterStethoscopeAndOtoscopeList } from "../../components/PatientHealthParameterStethoscopeAndOtoscopeList/PatientHealthParameterStethoscopeAndOtoscopeList";

type PatientHealthParameterStethoscopeAndOtoscopeListModalProps = {
  url: string;
  title: string;
  token: string;
  code: string;
  visitId?: string;
  patientId: string;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const PatientHealthParameterStethoscopeAndOtoscopeListModal: FC<
  PatientHealthParameterStethoscopeAndOtoscopeListModalProps
> = props => {
  const { title, token, code, visitId, patientId, url, isOpen, onClose: handleClose } = props;

  const [selectedUrl, setSelectedUrl] = useState(url);

  const onPatientHealthParameterStethoscopeAndOtoscopeListChange = (newUrl: string) => {
    setSelectedUrl(newUrl);
  };

  return (
    <CustomDialog type='base' open={isOpen} DialogTitleProps={{ title, onClose: handleClose }}>
      <HealthParameterStethoscopeAndOtoscopeType url={selectedUrl} isAutoplayEnabled />

      <Divider sx={{ marginBlock: 5 }} />

      <PatientHealthParameterStethoscopeAndOtoscopeList
        selectedUrl={selectedUrl}
        token={token}
        code={code}
        patientId={patientId}
        visitId={visitId}
        onChange={onPatientHealthParameterStethoscopeAndOtoscopeListChange}
      />
    </CustomDialog>
  );
};
