import { useReactiveVar } from "@apollo/client";
import { getRequiredValidation } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { branchUserPreferenceVar } from "./branchUserReactiveVar";
import { BranchUserProps } from "./types";
import withBranchUserData from "./withBranchUserData";
import { CitiesAutocomplete } from "@health/autocompletes";

const BranchUserForm: FC<BranchUserProps> = props => {
  const { t } = useTranslation("provider");
  const branchUserPreference = useReactiveVar(branchUserPreferenceVar);

  const { branches, refetch, onFetchMoreBranches, pageInfoBranches } = props;
  const {
    control,
    formState: { errors: formErrors },
    register,
  } = useFormContext();

  const handleSearchInput = name => {
    refetch({ filter: { name_Icontains: name, isActive: true } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CitiesAutocomplete {...register("city", { required: getRequiredValidation(t, true) })} />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteController
          ControllerProps={{
            name: "branch",
            control: control,
            defaultValue: branchUserPreference?.branch,
            rules: {
              required: getRequiredValidation(t, true),
            },
          }}
          getOptionLabel={option => pickLocalizedValue(option?.name, option?.nameAr)!}
          options={branches || []}
          onSearchInput={handleSearchInput}
          fetchMoreData={() => onFetchMoreBranches()}
          hasMore={pageInfoBranches?.hasNextPage}
          TextFieldProps={{
            placeholder: t("Branch"),
            label: t("Branch"),
            error: Boolean(formErrors?.branch?.message),
            helperText: t(formErrors?.branch?.message),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withBranchUserData(BranchUserForm);
