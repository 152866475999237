import { GuidedCareActivityType, GuidedCareJourneyItem, GuidedCareJourneyItemStatus } from "@health/queries/types";

export const getActivityDisplayName = (item: GuidedCareJourneyItem) => {
  switch (item?.guidedCareJourneyItemType) {
    case GuidedCareActivityType.OnlineVisit:
    case GuidedCareActivityType.FacilityVisit:
    case GuidedCareActivityType.HomeVisit:
    case GuidedCareActivityType.Lab:
    case GuidedCareActivityType.Rad:
      return item?.appointment?.doctor?.user?.fullName!;
    case GuidedCareActivityType.MedicalForm:
      return item?.medicalForm?.name!;
    case GuidedCareActivityType.MedicalMessageGeneral:
    case GuidedCareActivityType.MedicalMessageMedical:
    case GuidedCareActivityType.MedicalMessageLifestyleReminder:
    case GuidedCareActivityType.MedicalMessageMonitoring:
      return item?.medicalMessage?.name!;
    default:
      return "-";
  }
};

export const getStatusColor = (status: GuidedCareJourneyItemStatus, palette: any) => {
  switch (status) {
    case GuidedCareJourneyItemStatus.Pending:
    case GuidedCareJourneyItemStatus.Rescheduled:
      return palette.stale.main;
    case GuidedCareJourneyItemStatus.Missed:
    case GuidedCareJourneyItemStatus.Cancelled:
      return palette.error.main;
    default:
      return palette.error.main;
  }
};
