import { useVisitsVitalSignsChanged } from "@health/common";
import moment from "moment/moment";
import { getVitalSignsItemsLastItem } from "shared/modules/patient/components/VitalSigns/utils";
import { useVisitsVitalSignsQuery } from "shared/modules/patient/gql";
import { getApolloContextFormToken } from "shared/utils";
import { KPICard } from "./KPICard";
import { Box, CircularProgress, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";

type KPIViewProps = {
  token?: string;
  patientId?: string;
  userId?: string;
  visitId?: string;
};

export const KPIView: FC<KPIViewProps> = props => {
  const { token, patientId, userId, visitId } = props;

  const { t } = useTranslation("provider");

  const { data, loading, refetch } = useVisitsVitalSignsQuery({
    variables: {
      first: 10,
      filter: {
        patientId,
        visitId,
        patientUserId: userId,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const visitVitalSigns = data?.visitsVitalSigns?.edges?.map(item => ({
    ...item?.node,
    date: moment(item?.node?.modified).format("DD MMM YYYY HH:mm"),
  }));

  const vitalSignsItems = getVitalSignsItemsLastItem(visitVitalSigns!);

  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.patientId) {
      refetch({
        first: 10,
        filter: {
          patientId,
          visitId,
          patientUserId: userId,
        },
      });
    }
  });

  return loading ? (
    <Box display='flex' justifyContent='center' m={1}>
      <CircularProgress size={50} />
    </Box>
  ) : (
    <Grid item container spacing={2} alignContent='baseline'>
      <Grid item xs={12} sm={4} md={4} lg={2.4}>
        <KPICard
          title={t("Heart Rate")}
          value={Math.floor(vitalSignsItems?.heartRate?.value!)}
          unit={vitalSignsItems?.heartRate?.unit}
          description={vitalSignsItems?.heartRate?.source}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={2.4}>
        <KPICard
          title={t("Blood Pressure")}
          unit={vitalSignsItems?.bloodPressureLow?.unit}
          value={`${Math.floor(vitalSignsItems?.bloodPressureHigh?.value!) || 0}/${
            Math.floor(vitalSignsItems?.bloodPressureLow?.value!) || 0
          }`}
          description={vitalSignsItems?.bloodPressureHigh?.source}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={2.4}>
        <KPICard
          title={t("Respiration Rate")}
          unit={vitalSignsItems?.respiratoryRate?.unit}
          value={Math.floor(vitalSignsItems?.respiratoryRate?.value!)}
          description={vitalSignsItems?.respiratoryRate?.source}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={2.4}>
        <KPICard
          title={t("Temperature")}
          unit={vitalSignsItems?.temperature?.unit}
          value={vitalSignsItems?.temperature?.value!}
          description={vitalSignsItems?.temperature?.source}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={2.4}>
        <KPICard
          title={t("Oxygen Saturation")}
          unit={vitalSignsItems?.oxygenSaturation?.unit}
          value={Math.floor(vitalSignsItems?.oxygenSaturation?.value!)}
          description={vitalSignsItems?.oxygenSaturation?.source}
        />
      </Grid>
    </Grid>
  );
};
