/* eslint-disable max-lines */
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { productsPaths, productsRoute } from "./ProductsPaths";
import { ProductUpdateContainer, ProductCreateContainer, ProductsList } from "../containers";
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";

export const ProductsRoutes: (props: { navigate: NavigateFunction; t: TFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  t,
  enabled,
}) => {
  return {
    id: "marketplace-products",
    text: t("Products"),
    route: productsRoute,
    hidden: !enabled || !hasPermission(PermissionEnum.ManageProducts),
    isProhibited: !enabled || !hasPermission(PermissionEnum.ManageProducts),
    subItems: [
      {
        id: "products-list",
        text: t("Products"),
        route: productsPaths.main.route,
        fullPath: productsPaths.main.fullPath,
        element: <ProductsList />,
        hidden: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "products-new",
        text: t("New"),
        element: <ProductCreateContainer />,
        route: productsPaths.new.route,
        fullPath: productsPaths.new.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "products-update",
        text: t("Update"),
        element: <ProductUpdateContainer />,
        route: productsPaths.update.route,
        fullPath: productsPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};
