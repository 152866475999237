import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomIcon, IconButton, Tooltip, useAddToast, useGridContext, useModal } from "@toolkit/ui";
import { useChatFlowDeleteMutation } from "../gql";
export const DeleteButtons = ({ agentId, agentName }) => {
    const { t } = useTranslation("domains");
    const { open, handleToggle } = useModal();
    const { succeeded, failed } = useAddToast();
    const { refetch } = useGridContext();
    const [deleteAgentMutation, { loading }] = useChatFlowDeleteMutation({
        onCompleted: () => {
            succeeded("Agent deleted successfully");
            refetch({ first: 10, filter: null, after: null });
            handleToggle();
        },
        onError: ({ graphQLErrors }) => {
            failed(t(formatGraphQLError(graphQLErrors)));
        },
    });
    const handleDeleteAgent = () => {
        deleteAgentMutation({
            variables: {
                chatFlowDeleteId: agentId,
            },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: t("Delete Agent") }, { children: _jsx(IconButton, Object.assign({ onClick: handleToggle, disabled: loading }, { children: _jsx(CustomIcon, { icon: 'delete' }) })) })), open && (_jsx(CustomDialog, { type: 'delete', text: {
                    title: t("Delete {{agentName}} Agent", { agentName: agentName || "this" }),
                    body: t("Are you sure you want to delete this agent?"),
                }, isOpen: open, onClose: handleToggle, onDelete: handleDeleteAgent }))] }));
};
