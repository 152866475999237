import { useOpenStateController } from "@health/common";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  CircularProgress,
  CustomDialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  TruncateTypography,
  Typography,
  useAddToast,
} from "@toolkit/ui";
import React, { FC } from "react";
import { ellipseName } from "utils";
import { useOrderActionStyles } from "pages/GlobalOrders/Orders/actions/OrderAction.styles";
import { CircularIntegration } from "./CircularIntegration";
import { useGetCodeSystemConceptSearchQuery } from "shared/modules/patient/gql";
import { CodeSystemCode, Type } from "@health/queries/types";
import { useVendorRejectProductOrderMutation } from "../gql/mutations/__generated__";

type MarketplaceRejectReasonModelProps = {
  id: string;
};

export const MarketplaceRejectReasonButton: FC<MarketplaceRejectReasonModelProps> = ({ id }) => {
  const { failed, succeeded } = useAddToast();

  const { t } = useTranslation("provider");
  const { classes, theme } = useOrderActionStyles();
  const [rejectionReason, setRejectionReason] = React.useState("");
  const { open: isOpen, handleOpen, handleClose } = useOpenStateController();
  const { data, loading } = useGetCodeSystemConceptSearchQuery({
    variables: {
      keyword: "",
      first: 10,
      codeSystemCode: CodeSystemCode.RejectionReason,
      searchParameters: [{ valueString: "marketplace_rejection_reason", code: "type", type: Type.String }],
    },

    skip: !isOpen,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionReason((event.target as HTMLInputElement).value);
  };

  const orderRejectionReasons = data?.codeSystemConceptSearch?.edges?.map(reason => reason?.node);

  const [marketplaceOrderReject, { loading: loadingReject }] = useVendorRejectProductOrderMutation({
    onCompleted: () => {
      succeeded(t("Order Rejected Successfully"));
      handleClose();
    },
    onError: error => {
      failed(t(formatGraphQLError(error?.graphQLErrors)));
      handleClose();
    },
    update: cache => {
      const normalizedId = cache.identify({ id, __typename: "MarketplaceOrder" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });
  const handleOrderRejected = (): void => {
    const selectedReason = orderRejectionReasons?.find(reason => reason?.display === rejectionReason);
    marketplaceOrderReject({
      variables: {
        id: id,
        rejectionReason: selectedReason?.display!,
      },
    });
  };

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      onBackdropClick={handleClose}
      DialogTitleProps={{
        title: t("Reject Request Reason"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        children: (
          <CircularIntegration loading={loadingReject}>
            <Button onClick={handleOrderRejected} disabled={!rejectionReason} className={classes.reject}>
              {t("Reject")}
            </Button>
          </CircularIntegration>
        ),
      }}
      button={
        <Button color='error' variant='outlined' onClick={handleOpen}>
          {t("Reject")}
        </Button>
      }
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <RadioGroup value={rejectionReason} onChange={handleChange}>
          {orderRejectionReasons?.length ? (
            orderRejectionReasons?.map(reason => (
              <FormControlLabel
                key={reason?.display}
                value={reason?.display}
                control={<Radio />}
                label={
                  <TruncateTypography
                    text={String(ellipseName(reason?.display, 100))}
                    sx={{
                      fontSize: theme.mixins.fonts.fontSize.sm,
                      color: rejectionReason !== reason?.display ? theme.palette.stale.main : theme.palette.primary.main,
                    }}
                  />
                }
              />
            ))
          ) : (
            <Typography>{t("There is no Reject Request Reason")}</Typography>
          )}
        </RadioGroup>
      )}
    </CustomDialog>
  );
};
