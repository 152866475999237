/* eslint-disable max-lines */
import {
  Appointment,
  AppointmentStatus,
  AppointmentStatus as AppointmentStatusEnum,
  AppointmentTimeSlotInput,
  Maybe,
  Patient,
  TimeSlot,
} from "@health/queries/types";
import { toSentenceCase } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { Theme } from "@toolkit/ui";
import moment from "moment";
import { IFormattedTimeSlot, IGroupedAvailableTimeSlots } from "pages/appointments/types/types";
import { PageTabsStatusType, PageTabsType } from "shared/components/PageTabs";

export const appointmentFormatDate = "YYYY-MM-DD";

export enum AppointmentTabsStatus {
  AllRequest = "All Request",
  tentative = "Tentative",
  NewRequests = "New Requests",
  PendingConfirmation = "Pending Confirmation",
  Confirmed = "Confirmed",
  OngoingVisit = "Ongoing Visit",
  Done = "Done",
  Cancelled = "Cancelled",
}

export const getAppointmentsTabs = (t: TFunction, theme: Theme): PageTabsType => [
  {
    title: t("New Requests", { ns: "provider" }),
    color: theme.palette.primary.main,
    status: AppointmentTabsStatus.NewRequests,
  },
  {
    title: t("Pending Confirmation", { ns: "provider" }),
    color: theme.palette.warning.main,
    status: AppointmentTabsStatus.PendingConfirmation,
  },
  {
    title: t("Confirmed", { ns: "provider" }),
    color: theme.palette.success.main,
    status: AppointmentTabsStatus.Confirmed,
  },
  {
    title: t("Ongoing Visit", { ns: "provider" }),
    color: theme.palette.yellow.main,
    status: AppointmentTabsStatus.OngoingVisit,
  },
  {
    title: t("Done", { ns: "provider" }),
    color: theme.palette.stale.main,
    status: AppointmentTabsStatus.Done,
  },
  {
    title: t("Cancelled", { ns: "provider" }),
    color: theme.palette.error.main,
    status: AppointmentTabsStatus.Cancelled,
  },
];

export const mapTabStatesToTotalCountKey = (status: PageTabsStatusType) => {
  switch (status) {
    default:
    case AppointmentTabsStatus.AllRequest:
      return "all";
    case AppointmentTabsStatus.tentative:
      return "tentative";
    case AppointmentTabsStatus.NewRequests:
      return "new";
    case AppointmentTabsStatus.PendingConfirmation:
      return "pending";
    case AppointmentTabsStatus.Confirmed:
      return "confirmed";
    case AppointmentTabsStatus.OngoingVisit:
      return "ongoing";
    case AppointmentTabsStatus.Cancelled:
      return "cancelled";
    case AppointmentTabsStatus.Done:
      return "done";
  }
};

export const getFilter = (selfOnly: boolean, filterInput?) => {
  return {
    filterTentative: {
      selfOnly,
      status: mapTabStatesToAppointmentsStatus(AppointmentTabsStatus.tentative),
      ...filterInput,
    },
    filterNew: {
      selfOnly,
      status: mapTabStatesToAppointmentsStatus(AppointmentTabsStatus.NewRequests),
      ...filterInput,
    },
    filterPending: {
      selfOnly,
      status: mapTabStatesToAppointmentsStatus(AppointmentTabsStatus.PendingConfirmation),
      ...filterInput,
    },
    filterConfirmed: {
      selfOnly,
      status: mapTabStatesToAppointmentsStatus(AppointmentTabsStatus.Confirmed),
      ...filterInput,
    },
    filterOngoing: {
      selfOnly,
      confirmedEndTime: {
        gte: moment().format(),
      },
      status: mapTabStatesToAppointmentsStatus(AppointmentTabsStatus.OngoingVisit),
      ...filterInput,
    },
    filterCancelled: {
      selfOnly,
      status: mapTabStatesToAppointmentsStatus(AppointmentTabsStatus.Cancelled),
      ...filterInput,
    },
    filterDone: {
      selfOnly,
      status: mapTabStatesToAppointmentsStatus(AppointmentTabsStatus.Done),
      ...filterInput,
    },
  };
};

export const groupTimeSlotsByTimeOfDay = (timeSlots: TimeSlot[] = []): IGroupedAvailableTimeSlots => {
  const morningSlots: IFormattedTimeSlot[] = [];
  const afternoonSlots: IFormattedTimeSlot[] = [];
  const eveningSlots: IFormattedTimeSlot[] = [];

  timeSlots?.forEach(timeSlot => {
    const formattedSlot: IFormattedTimeSlot = {
      ...timeSlot,
      startTimeFormatted: moment(timeSlot.startTime).format("hh:mm A"),
      endTimeFormatted: moment(timeSlot.endTime).format("hh:mm A"),
    };

    const startTimeHour = parseInt(moment(timeSlot.startTime).format("HH"), 10);

    if (startTimeHour < 12) {
      morningSlots.push(formattedSlot);
    } else if (startTimeHour < 18) {
      afternoonSlots.push(formattedSlot);
    } else {
      eveningSlots.push(formattedSlot);
    }
  });

  return { morningSlots, afternoonSlots, eveningSlots };
};

export const convertAppointmentTimeSlotToBackEndValue = (timeSlot: IFormattedTimeSlot): AppointmentTimeSlotInput => {
  return {
    startTime: timeSlot.startTime,
    endTime: timeSlot.endTime,
  };
};

export const isDateExpired = (date?: string) => {
  if (!date) return false;
  return moment(date, "YYYY-MM-DD").isBefore(moment(), "day");
};

export const mapTabStatesToAppointmentsStatus = (status: PageTabsStatusType) => {
  switch (status) {
    case AppointmentTabsStatus.NewRequests:
      return [AppointmentStatusEnum.Request, AppointmentStatusEnum.RescheduledByConsumer];
    case AppointmentTabsStatus.tentative:
      return [AppointmentStatusEnum.Tentative];
    case AppointmentTabsStatus.PendingConfirmation:
      return [AppointmentStatusEnum.PendingApproval, AppointmentStatusEnum.RescheduledByDoctor];
    case AppointmentTabsStatus.Confirmed:
      return [
        AppointmentStatusEnum.Approved,
        AppointmentStatusEnum.ComingSoon,
        AppointmentStatusEnum.Confirmed,
        AppointmentStatusEnum.PaymentSucceeded,
      ];
    case AppointmentTabsStatus.OngoingVisit:
      return [AppointmentStatusEnum.ChatInProgress, AppointmentStatusEnum.CallInProgress];
    case AppointmentTabsStatus.Cancelled:
      return [
        AppointmentStatusEnum.CanceledByDoctor,
        AppointmentStatus.CanceledByConsumer,
        AppointmentStatusEnum.Cancelled,
        AppointmentStatusEnum.Rejected,
      ];
    case AppointmentTabsStatus.Done:
      return [AppointmentStatusEnum.FinishedSuccessfully];
    default:
    case AppointmentTabsStatus.AllRequest:
      return null;
  }
};

export const mapTabStatesToFilterInput = (status: PageTabsStatusType) => {
  switch (status) {
    case AppointmentTabsStatus.NewRequests:
      return { status: [AppointmentStatusEnum.Request, AppointmentStatusEnum.RescheduledByConsumer] };
    case AppointmentTabsStatus.tentative:
      return { status: [AppointmentStatusEnum.Tentative] };
    case AppointmentTabsStatus.PendingConfirmation:
      return {
        status: [AppointmentStatusEnum.PendingApproval, AppointmentStatusEnum.RescheduledByDoctor],
      };
    case AppointmentTabsStatus.Confirmed:
      return {
        status: [
          AppointmentStatusEnum.Approved,
          AppointmentStatusEnum.ComingSoon,
          AppointmentStatusEnum.Confirmed,
          AppointmentStatusEnum.PaymentSucceeded,
        ],
      };
    case AppointmentTabsStatus.OngoingVisit:
      return { status: [AppointmentStatusEnum.ChatInProgress, AppointmentStatusEnum.CallInProgress] };
    case AppointmentTabsStatus.Cancelled:
      return {
        status: [
          AppointmentStatusEnum.CanceledByDoctor,
          AppointmentStatus.CanceledByConsumer,
          AppointmentStatusEnum.Cancelled,
          AppointmentStatusEnum.Rejected,
        ],
      };
    case AppointmentTabsStatus.Done:
      return { status: [AppointmentStatusEnum.FinishedSuccessfully] };
    default:
    case AppointmentTabsStatus.AllRequest:
      return { status: null };
  }
};

export const getPatientName = (patient?: Maybe<Patient>) => {
  if (patient?.firstName && patient?.lastName) {
    return patient.firstName + " " + patient.lastName;
  } else {
    return "Patient";
  }
};

export const getAppointmentStatusTitle = (status?: Maybe<AppointmentStatus>) => {
  if (
    status === AppointmentStatus.ComingSoon ||
    status === AppointmentStatus.Rejected ||
    status === AppointmentStatus.CanceledByConsumer ||
    status === AppointmentStatus.CanceledByDoctor ||
    status === AppointmentStatus.Cancelled
  ) {
    return `You can't cancel ${toSentenceCase(status)} Appointment`;
  }

  return "";
};

export const getTimeFrame = (data: Appointment) => {
  if (data?.requestedTimeSlots?.[0]) {
    return data?.requestedTimeSlots
      ?.map(slot => `${moment(JSON.parse(slot!)?.startTime).format("hh:mm A")} - ${moment(JSON.parse(slot!)?.endTime).format("hh:mm A")}`)
      .join(", ");
  } else if (data?.suggestedTimeSlots?.[0]) {
    return data?.suggestedTimeSlots
      ?.map(slot => `${moment(JSON.parse(slot!)?.startTime).format("hh:mm A")} - ${moment(JSON.parse(slot!)?.endTime).format("hh:mm A")}`)
      .join(", ");
  } else {
    return "-";
  }
};
