import { AccountDropdownMenu, CustomerIcon, IconButton, UserIcon } from "@toolkit/ui";
import React from "react";
import { useOidcUserProfile } from "../../hooks/useOidcUserProfile";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { meetoraClient } from "@health/meetora";
import { useAuth } from "react-oidc-context";
import { useGetMeDataQuery } from "../../../pages/profile/gql";
import { removeUserWorkingDetails } from "../UserWorkspaceSelection";
import { isDoctorUser } from "utils";

export const UserProfileMenu = () => {
  const navigate = useNavigate();

  const { userInfo } = useOidcUserProfile();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const username = userInfo?.name! || userInfo?.email! || "User";
  const isOpen = Boolean(anchorEl);
  const auth = useAuth();
  const authenticated = !!auth.isAuthenticated;
  const { data } = useGetMeDataQuery();
  const photo = data?.me?.doctor?.user?.photo;

  const handleLogout = async () => {
    setAnchorEl(null);
    await meetoraClient.logout();
    await auth.removeUser();
    await auth.clearStaleState();
    await auth.signoutRedirect({
      id_token_hint: auth.user?.id_token,
      state: auth.user?.state,
      post_logout_redirect_uri: window.location.origin,
    });
    await auth.revokeTokens();
    removeUserWorkingDetails(userInfo.vendor_user_type);
  };
  const handleClickClose = () => {
    setAnchorEl(null);
  };
  const handleGoToDoctorProfile = () => {
    navigate("/doctor/profile");
  };
  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {authenticated && (
        <IconButton onClick={handleClickOpen} color={isOpen ? "info" : "primary"}>
          <CustomerIcon />
        </IconButton>
      )}

      {isOpen && (
        <AccountDropdownMenu
          username={username}
          open={isOpen}
          imgSrc={photo!}
          onLogout={handleLogout}
          items={
            isDoctorUser(userInfo)
              ? [
                  {
                    icon: (
                      <UserIcon
                        sx={{
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    ),
                    title: t("My Profile"),
                    onClick: handleGoToDoctorProfile,
                  },
                ]
              : []
          }
          anchorEl={anchorEl}
          onClose={handleClickClose}
        />
      )}
    </>
  );
};
